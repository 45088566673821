<template>
  <main class="d-flex flex-column min-vh-100 bg-light">
    <div
      class="flex-grow-1 d-flex align-items-center justify-content-center flex-column my-3"
    >
      <div
        class="d-flex justify-content-center align-items-start w-100 cu-md-flex"
      >
        <div
          class="p-3 d-flex flex-column rounded-4 shadow w-100 mx-4 bg-body cu-margin-x"
        >
          <div
            class="d-flex justify-content-center align-items-center flex-column"
          >
            <h1 class="fs-1 fw-bolder">Fill The Details</h1>
            <p class="fs-4 fw-bolder">
              {{
                capitalizeFirstLetter(
                  "Enter Your Company Details to Purchase the Product."
                )
              }}
            </p>

            <p class="text-danger">{{ formError }}</p>
          </div>

          <div class="my-3" style="border-bottom: 4px dashed black"></div>

          <div class="container">
            <!-- Email & Company  -->
            <div class="row my-3">
              <div class="col-sm-6">
                <label for="">Email Address</label>
                <div class="input-group">
                  <input
                    type="email"
                    class="form-control bg-light"
                    v-model="email"
                    :disabled="emailVerified"
                  />

                  <button
                    class="btn text-uppercase"
                    @click="getEmail"
                    :disabled="emailVerified || !email"
                  >
                    Verify
                  </button>
                </div>
              </div>

              <div class="col-sm-6">
                <label for="">Company Name</label>
                <input
                  type="text"
                  class="form-control bg-light"
                  :value="companyName"
                  disabled
                />
              </div>
            </div>

            <!-- Company Address  -->
            <div class="row my-3">
              <div class="col-sm-12">
                <label for="">Company Address</label>
                <input
                  type="text"
                  class="form-control bg-light"
                  :value="companyAddress"
                  disabled
                />
              </div>
            </div>

            <!-- Last name -->
            <div class="row my-3">
              <div class="col-sm-6">
                <label for="">First Name</label>
                <input
                  type="text"
                  class="form-control bg-light"
                  disabled
                  :value="first_name"
                />
              </div>

              <div class="col-sm-6">
                <label for="">Last Name</label>
                <input
                  type="text"
                  class="form-control bg-light"
                  disabled
                  :value="last_name"
                />
              </div>
            </div>

            <!-- country & State  -->
            <div class="row my-3">
              <div class="col-sm-6">
                <label for="">Country</label>

                <input
                  type="text"
                  class="form-control bg-light"
                  disabled
                  :value="country"
                />
              </div>

              <div class="col-sm-6">
                <label for="">State</label>
                <input
                  type="text"
                  class="form-control bg-light"
                  disabled
                  :value="state"
                />
              </div>
            </div>

            <!-- city & pincode  -->
            <div class="row my-3">
              <div class="col-sm-6">
                <label for="">City</label>
                <input
                  type="text"
                  class="form-control bg-light"
                  disabled
                  :value="city"
                />
              </div>

              <div class="col-sm-6">
                <label for="">Pincode</label>
                <input
                  type="number"
                  class="form-control bg-light"
                  disabled
                  :value="pincode"
                />
              </div>
            </div>

            <!-- mobile number & Sales Manager Code & Gst Number -->
            <div class="row my-3">
              <div class="col-sm-6">
                <label for="">Mobile Number</label>
                <input
                  type="number"
                  class="form-control bg-light"
                  disabled
                  :value="mobile_no"
                />
              </div>

              <div class="col-sm-3">
                <label for="">Sales Manager Code</label>
                <input type="text" class="form-control bg-light" disabled />
              </div>

              <div class="col-sm-3">
                <label for="">Gst Number</label>
                <input type="text" class="form-control bg-light" disabled />
              </div>
            </div>
          </div>
        </div>

        <!-- Left side code -->
        <div class="d-flex flex-column w-50 cu-md-flex-direction">
          <!-- Select Plan -->
          <div
            class="p-4 flex-grow-1 rounded-4 shadow mx-4 mb-3 mb-md-0 bg-body cu-plan-margin-x"
          >
            <div
              class="d-flex justify-content-center align-items-center flex-column"
            >
              <h1
                class="fs-1 fw-bolder text-center text-decoration-underline text-decoration"
              >
                Select Plan
              </h1>

              <p class="p-0 fs-6 text-center fw-semibold">
                Explore our Free Trial and discover the perfect plan for your
                needs.
              </p>
            </div>

            <div
              class="p-2 w-100 d-flex justify-content-center align-items-center rounded-5"
              style="background-color: #bcbcbcc0"
            >
              <!-- Left Side: Anual Subscription -->
              <!-- <p
                class="m-0 p-2 text-white"
                :class="{ 'bg-primary': selectedSide === 'left' }"
                @click="selectLeft"
                style="cursor: pointer"
              >
                Anual Subscription
              </p> -->

              <!-- Right Side: Per User Subscription -->
              <!-- :class="{ 'bg-primary': selectedSide === 'right' }" -->
              <!-- @click="selectRight" -->
              <p
                class="m-0 p-2 fs-4 fw-semibold text-white text-center rounded-5 w-100"
                style="cursor: pointer; background-color: #00abf5"
              >
                Per User Subscription
              </p>

              <!-- Slider -->
              <!-- <div class="slider" :style="{ transform: sliderTransform }"></div> -->
            </div>

            <!-- here select the plan  -->
            <div class="w-100 d-flex flex-column">
              <div class="mt-3">
                <p class="p-0 fs-6 text-center fw-semibold">
                  Best Per User Subscription Plan in India = Rs 50 per User per
                  *month
                </p>
              </div>

              <div class="container">
                <div class="row">
                  <!-- select specific user -->
                  <div class="col-md-4">
                    <label for="" class="text-decoration fs-6"
                      >Enter User:</label
                    >
                    <select v-model="selectedValue" class="form-control">
                      <option
                        v-for="(option, index) in options"
                        :key="index"
                        :value="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>

                  <!-- select Years user -->
                  <div class="col-md-4">
                    <label for="" class="text-decoration fs-6"
                      >Enter Year
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="userYear"
                    />
                  </div>

                  <!-- select Years user -->
                  <div class="col-md-4">
                    <label for="" class="text-decoration fs-6">Amount </label>
                    <input type="number" class="form-control" :value="amount" />
                  </div>

                  <div class="d-flex justify-content-start align-items-center">
                    <p class="mt-4 mb-0 p-0 fs-6">
                      *All prices are excluded GST!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- billling information -->
          <div
            class="p-4 flex-grow-1 rounded-4 shadow mt-5 mx-4 bg-body cu-margin-top cu-billing-margin-x"
          >
            <div class="d-flex justify-content-start align-items-center w-100">
              <h1 class="text-decoration text-decoration-underline">
                BILLING INFORMATION
              </h1>
            </div>

            <div class="d-flex justify-content-between w-100 my-3">
              <h1 class="billing-text-decoration fs-3">Product Name</h1>
              <h1 class="billing-text-decoration fs-3">Rate(₹)</h1>
            </div>

            <div class="my-3" style="border-bottom: 2px dashed rgb(190, 190, 190)"></div>


            <div class="d-flex justify-content-between w-100 my-1">
              <h1 class="fs-4 fw-semibold">Price</h1>
              <h1 class="fs-4 fw-semibold">{{ amount || "0.00" }}</h1>
            </div>

            <div class="d-flex justify-content-between w-100 my-1">
              <h1 class="fs-4 ">C-GST(9%):</h1>
              <h1 class="fs-4 fw-semibold">{{ cgst || "0.00" }}</h1>
            </div>

            <div class="d-flex justify-content-between w-100 my-1">
              <h1 class="fs-4 ">S-GST(9%):</h1>
              <h1 class="fs-4 fw-semibold">{{ sgst || "0.00" }}</h1>
            </div>

            <div class="my-3" style="border-bottom: 2px dashed rgb(190, 190, 190)"></div>

            <div class="d-flex justify-content-between w-100 my-3 ">
              <h1 class="fs-4 fw-semibold">Total Payment:</h1>
              <h1 class="fs-4 fw-semibold">{{ total_amount || "0.00" }}</h1>
            </div>

            <div class="w-100 d-flex justify-content-center align-items-center">
              <button
                class="btn btn-decoration w-100 text-white fs-4"
                @click="sendBillingInfo"
              >
                <!-- @click="payNow" -->
                PAY NOW
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->

      <!-- PayU form -->
      <!-- <form method="POST" class="pl-5 pr-5" id="paymentForm" :action="payuUrl"> -->
      <form
        method="POST"
        class="pl-5 pr-5"
        id="paymentForm"
        :action="payuUrl"
        @submit.prevent="generateHashAndSubmitForm"
      >
        <input type="hidden" name="key" v-model="mkey" size="64" />
        <input type="hidden" name="txnid" v-model="txnid" size="64" />

        <!-- <input type="hidden" name="amount" v-model="amount_pay" size="64" /> -->
        <input type="hidden" name="amount" v-model="total_amount" size="64" />

        <input
          type="hidden"
          name="productinfo"
          v-model="productInfo"
          size="64"
        />

        <input type="hidden" name="firstname" v-model="first_name" size="64" />
        <input
          type="hidden"
          name="service_provider"
          value="payu_paisa"
          size="64"
        />
        <input type="hidden" name="email" v-model="email" size="64" />

        <input type="hidden" name="phone" v-model="mobile_no" size="64" />

        <input type="hidden" name="lastname" v-model="last_name" size="64" />
        <input type="hidden" name="surl" v-model="surl" />
        <input type="hidden" name="furl" v-model="furl" />
        <input type="hidden" name="hash" id="hash" v-model="hash" size="64" />
      </form>
    </div>

    
  </main>
  <Footer />
</template>

<script>
import Footer from "../Footer.vue";
import axios from "axios";
import sha512 from "js-sha512";

export default {
  name: "AddRemoverPlan",
  data() {
    return {
      // selectedSide: "left",

      apiUrl: "https://uatbackendsales.infinitybrains.com/public/api/",
      // apiUrl: "pending",
      addFreeSelected: false,
      uploadLogoSelected: false,

      selectedValue: "1",
      options: [],
      numberOfUser: "",
      userYear: "",
      userPlanAmount: "",

      userDetails: [],
      userPlanDetails: [],
      userVendor: [],
      userLocation: [],

      formError: "",

      cgst: "",
      sgst: "",
      gst: "",
      amount: "",
      total_amount: "",
      first_name: "",
      last_name: "",
      mobile_no: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      uIdUpdPay: "",
      companyName: "",
      companyAddress: "",
      salseManagerCode: "",
      gstNumber: "",
      email: "",
      emailVerified: false,
      message: "",
      // amount_pay: '678.64',
      hash: "",
      txnid: "",
      productInfo: "HRMS Add Free",
      payuUrl: "https://secure.payu.in/_payment",
      mkey: "nxpvv9VZ",
      saltKey: "3oFxUMtWG2",
      // surl: 'https://app.ibdelight.in/success',
      surl: window.location.origin + "/success",
      // furl: 'https://app.ibdelight.in/',
      furl: window.location.origin + "/",
      // furl: 'https://ibhrms.com/',
    };
  },

  components: {
    Footer,
  },

  // add watcher of update the billing info dynamicaly
  watch: {
    selectedValue: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.updateBillingInfo();
        }
      },
      immediate: true,
    },
    userYear: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.updateBillingInfo();
        }
      },
      immediate: true,
    },
  },

  // here calculate the user and user plan and year

  created() {
    for (let i = 1; i <= 100; i++) {
      if (i === 1) {
        this.options.push({ text: i.toString(), value: i.toString() });
      } else if (i === 10) {
        this.options.push({ text: "1-10", value: "1" });
      } else if (i >= 11) {
        this.options.push({ text: i.toString(), value: i.toString() });
      }
    }

    this.selectedValue = "1";
    this.userYear = 1;
    this.userPlanAmount = this.amount;
  },

  // computed: {
  //   sliderTransform() {
  //     return this.selectedSide === "left"
  //       ? "translateX(0)"
  //       : "translateX(96.1%)";
  //   },
  // },

  mounted() {
    this.makeid();
  },

  methods: {
    // selectLeft() {
    //   this.selectedSide = "left";
    // },

    // selectRight() {
    //   this.selectedSide = "right";
    // },

    capitalizeFirstLetter(text) {
      return text.replace(/\b\w/g, (char) => char.toUpperCase());
    },

    handleAddFreeCheckbox() {
      if (this.addFreeSelected) {
        console.log("ADD-FREE selected");
      } else {
        console.log("ADD-FREE unselected");
      }
    },

    handleUploadLogoCheckbox() {
      if (this.uploadLogoSelected) {
        console.log("Uploaded-Logo selected");
      } else {
        console.log("Uploaded-Logo unselected");
      }
    },

    toggleAddFreeCheckbox() {
      this.addFreeSelected = !this.addFreeSelected;
      this.handleAddFreeCheckbox();
    },

    toggleUploadLogoCheckbox() {
      this.uploadLogoSelected = !this.uploadLogoSelected;
      this.handleUploadLogoCheckbox();
    },

    async getEmail() {
      // Check if the email is empty
      if (!this.email.trim()) {
        this.formError = "Please enter your email address.";
        return;
      }

      try {
        const response = await axios.get(`${this.apiUrl}get-details-add`, {
          params: {
            email: this.email,
          },
        });

        // console.log("API Response:", response.data);

        if (
          response.data.status === true &&
          response.data.status_code === "200"
        ) {
          this.isEmailVerified = true;
          // Store the user information from the API response
          this.userDetails = response.data.data;
          this.userPlanDetails = response.data.plan;
          this.userVendor = response.data.vendor;
          this.userLocation = response.data.location;

          // access the data of user details from api
          this.email = this.userDetails.email;
          this.first_name = this.userDetails.first_name;
          this.last_name = this.userDetails.last_name;
          this.companyName = this.userVendor.company_name;
          this.companyAddress = this.userVendor.address;

          // user location
          this.country = this.userLocation.country_details.country_name;
          this.state = this.userLocation.state_details.state_name;
          this.city = this.userLocation.city_details.city_name;
          this.pincode = this.userDetails.pin_code;

          this.mobile_no = this.userDetails.mobile_no;
          // this.salseManagerCode = this.userDetails.salseManagerCode;
          // this.gstNumber = this.userDetails.gstNumber;

          // this.countryDetails=response.data
          // console.log("user Details = ", this.userDetails);
          // console.log("user Plan Details = ", this.userPlanDetails);
          // console.log("user vendors = ", this.userVendor.company_name);
          // console.log("user Locations = ", this.userLocation);
        } else {
          this.formError = "User not found. Enter a valid email address.";
          setTimeout(() => {
            this.formError = "";
          }, 5000);
          console.error("Error verifying email else:", response.data.message);
        }
      } catch (error) {
        this.formError = "Enter a valid email address.";
        setTimeout(() => {
          this.formError = "";
        }, 5000);
        console.error("Error verifying email:", error);
      }
    },

    async updateBillingInfo() {
      let totalMonths = parseInt(this.userYear) * 12;
      let selectedValue = parseInt(this.selectedValue);
      let amount;
      if (selectedValue === 1 || selectedValue === 10) {
        amount = 10 * totalMonths * 50;
      } else {
        amount = selectedValue * totalMonths * 50;
      }
      this.amount = amount;
      this.cgst = ((9 / 100) * this.amount).toFixed(2);
      this.sgst = ((9 / 100) * this.amount).toFixed(2);
      this.total_amount = (
        Number(this.amount) +
        Number(this.cgst) +
        Number(this.sgst)
      ).toFixed(2);
      // console.log(this.amount);
    },

    async sendBillingInfo() {
      try {
        const newSelectedValue = parseInt(this.selectedValue);
        const formData = {
          email: this.email,
          plan_id: "69",
          years: this.userYear,
          users: newSelectedValue,
          // cgst: this.cgst,
          // sgst: this.sgst,
          // amount: this.amount,
          // total_amount: this.total_amount,
        };

        axios
          .post(`${this.apiUrl}verify-email-advertisment`, formData)
          .then((response) => {
            this.uIdUpdPay = response.data.plan.id;
            // console.log(this.uIdUpdPay);
            this.payNow();
            // console.log("pay now");
          });
      } catch (err) {
        console.log("Eror :- ", err);
      }
    },

    // payment integarte
    payNow() {
      this.emailVerified = true;
      if (this.emailVerified) {
        this.generateHashAndSubmitForm();
      }
    },

    generateHashAndSubmitForm() {
      const data =
        this.mkey +
        "|" +
        this.txnid +
        "|" +
        // this.amount_pay +
        this.total_amount +
        "|" +
        this.productInfo +
        "|" +
        this.first_name +
        "|" +
        this.email +
        "|||||||||||";

      this.hash = sha512(data + this.saltKey);

      if (this.hash) {
        localStorage.setItem("hash", this.hash);
        localStorage.setItem("expireSession", "sesion12dgtdb");
      }

      console.log(this.hash);
      console.log(data);

      document.getElementById("hash").value = this.hash;

      const paymentData = {
        hashKey: this.hash,
        UserId: this.uIdUpdPay,
      };

      localStorage.setItem(
        "paymentAddRemoverPlan",
        JSON.stringify(paymentData)
      );

      document.getElementById("paymentForm").submit();
    },

    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      this.txnid = text;
    },
  },
};
</script>

<style scoped>

input:focus {
  outline: none;
  box-shadow: none;
}
.btn{
  background-color: #00abf5;
  border: none;
  color: white;
  font-weight: 700;
  
}
.text-decoration {
  font: normal normal bold 1.7rem Open Sans;
  color: #0aafe3;
  text-transform: capitalize;
}

.billing-text-decoration {
  color: #0aafe3;
  text-transform: capitalize;
}

.btn-decoration {
  background-color: #0aafe3 !important;
  font-weight: 500;
}

.btn-decoration:hover {
  background-color: #119bc5 !important;
}
main{
  padding:50px 200px;
}
/* slider styling */
/* .bg-primary {
  border-radius: 5rem;
  background-color: #00abf5 !important;
  transition: background-color 0.3s ease;
}

.slider {
  padding: 4.8%;
  border-radius: 24px;
  height: -moz-max-content;
  height: max-content;
  position: absolute;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  z-index: 0;
  font-weight: 900;
  transition: transform 0.3s ease-in-out;
} */

@media (max-width: 1200px) {
  .cu-md-flex {
    flex-direction: column !important;
    padding: 1rem !important;
  }

  .cu-md-flex-direction {
    flex-direction: row !important;
    width: 100% !important;
    margin-top: 5rem;
  }

  .cu-margin-top {
    margin-top: 0rem !important;
  }

  .cu-margin-x {
    margin: 0 !important;
    padding: 1px;
  }

  main{
  padding:0px;
  }


  
}

@media (max-width: 864px) {
  .cu-md-flex-direction {
    flex-direction: column !important;
  }

  .cu-plan-margin-x {
    margin: 0 !important;
  }
  .cu-billing-margin-x {
    margin: 0 !important;
    margin-top: 3rem !important;
  }
  main{
  padding:0px;
  }
}
</style>