<template>
  <div class="main-container">
    <div class="container" style="max-width: 1470px; margin-top: 3%">
      <div class="row" style="gap: 40px">
        <div
          class="col-lg-7 col-md-6 col-sm-12 left-column"
          id="left-column1"
          style=""
        >
          <div class="inner-container">
            <h2>Fill The Details</h2>
            <p style="margin-top: -2%">
              Enter Your Company Details to Purchase the Product.
            </p>
            <p
              v-if="freeTrial"
              style="
                margin-top: -2%;
                font-weight: 700;
                color: rgba(230, 137, 129, 255);
              "
            >
              15 Days Free Trials
            </p>
            <p style="font-size: 1rem" :class="messageClass">{{ formError }}</p>
          </div>
          <div
            style="
              border: 2px #464545 dashed;
              margin-top: 4%;
              margin-bottom: 6%;
            "
          ></div>
          <div class="form">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="mb-3">
                  <label for="email">Email Address</label>
                  <div class="input-group">
                    <input
                      type="email"
                      id="email"
                      class="form-control"
                      :disabled="emailDisabled"
                      v-model="email"
                      :class="getClassNames"
                    />
                    <!-- <div class="input-group-append">
                        <button class="btn btn-primary" v-if="virify" type="button" @click="verifyEmail" :disabled="loading">
                          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span v-else>Verify</span>
                        </button>
                        <button class="btn btn-secondary" type="button" @click="resetForm" v-if="emailDisabled && responseStatus === 'success'">
                          <i class="fas fa-times"></i>
                        </button>
                      </div> -->
                  </div>
                </div>

                <div class="mb-3">
                  <label for="companyName">Company Name</label>
                  <input
                    type="text"
                    id="companyName"
                    class="form-control"
                    v-model="companyName"
                  />
                </div>
                <div class="mb-3">
                  <div class="row" v-if="!freeTrial">
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                      <label for="firstName">First Name</label>
                      <input
                        type="text"
                        id="firstName"
                        class="form-control"
                        v-model="firstName"
                      />
                    </div>
                    <div class="col-lg-12 col-sm-12 col-md-12 mb-0">
                      <label for="lastName">Last Name</label>
                      <input
                        type="text"
                        id="lastName"
                        class="form-control"
                        v-model="lastName"
                      />
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                      <label for="firstName">First Name</label>
                      <input
                        type="text"
                        id="firstName"
                        class="form-control"
                        v-model="firstName"
                      />
                    </div>
                    <div class="col-lg-12 col-sm-12 col-md-12 mb-0">
                      <label for="lastName">Last Name</label>
                      <input
                        type="text"
                        id="lastName"
                        class="form-control"
                        v-model="lastName"
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="contactNumber">Mobile Number</label>
                  <input
                    type="text"
                    id="contactNumber"
                    class="form-control"
                    v-model="contactNumber"
                  />
                </div>

                <div class="mb-3" v-if="!freeTrial">
                  <label for="password">Create Password</label>
                  <div class="input-group">
                    <input
                      :type="showPassword ? 'text' : 'password'"
                      id="password"
                      class="form-control"
                      v-model="createPassword"
                      required
                    />
                    <div
                      class="input-group-append"
                      style="background-color: transparent"
                    >
                      <span class="input-group-text" style="height: 100%">
                        <i
                          :class="[
                            showPassword ? 'fas fa-eye' : 'fas fa-eye-slash',
                          ]"
                          @click="togglePasswordVisibility"
                          style="cursor: pointer; color: gray"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="mb-3" v-if="freeTrial">
                  <label for="companyAddress">Company Address</label>
                  <input
                    type="text"
                    id="companyAddress"
                    class="form-control"
                    v-model="companyAddress"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="mb-3" v-if="!freeTrial">
                  <label for="companyAddress">Company Address</label>
                  <input
                    type="text"
                    id="companyAddress"
                    class="form-control"
                    v-model="companyAddress"
                  />
                </div>
                <div class="mb-3" v-if="!freeTrial">
                  <label for="LogoGet">Company Logo</label>
                  <input
                    type="file"
                    id="LogoGet"
                    class="form-control custom-file-input"
                    accept="image/*"
                    ref="fileInput"
                    @change="handleFileUpload1"
                  />
                </div>

                <div class="mb-3" v-if="freeTrial">
                  <label for="password">Password</label>
                  <div class="input-group">
                    <input
                      :type="showPassword ? 'text' : 'password'"
                      id="password"
                      class="form-control"
                      v-model="createPassword"
                      required
                    />
                    <div
                      class="input-group-append"
                      style="background-color: transparent"
                    >
                      <span class="input-group-text" style="height: 100%">
                        <i
                          :class="[
                            showPassword ? 'fas fa-eye' : 'fas fa-eye-slash',
                          ]"
                          @click="togglePasswordVisibility"
                          style="cursor: pointer; color: gray"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="row">
                    <!-- <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <label for="country">Country</label>
                  <div class="select-wrapper">
                    <select id="country" class="form-control" v-model="country_id"  @change="getState()" required>
                      <option value="">Select Country</option>

                      <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                    </select>
                    <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrow.png" class="arrow-image" alt="Dropdown Arrow">
                  </div>
                </div> -->

                    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                      <label for="state">State</label>
                      <select
                        id="state"
                        class="form-control"
                        v-model="state_id"
                        @change="getCity()"
                        required
                      >
                        <option value="">Select State</option>

                        <option
                          v-for="state in states"
                          :key="state.id"
                          :value="state.id"
                        >
                          {{ state.name }}
                        </option>
                      </select>
                      <img
                        src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrow.png"
                        class="arrow-image2"
                        alt="Dropdown Arrow"
                      />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-0">
                      <label for="city">City</label>
                      <select id="city" class="form-control" v-model="city_id">
                        <option value="">Select City</option>

                        <option
                          v-for="city in cities"
                          :key="city.id"
                          :value="city.id"
                        >
                          {{ city.name }}
                        </option>
                      </select>
                      <img
                        src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrow.png"
                        class="arrow-image1"
                        alt="Dropdown Arrow"
                      />
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <label for="pincode">Pincode</label>
                      <input
                        type="text"
                        id="pincode"
                        class="form-control"
                        v-model="pincode"
                      />
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                      <label for="salesManager">Sales Manager Code</label>
                      <input
                        type="text"
                        id="salesManager"
                        class="form-control"
                        v-model="salesManager"
                      />
                    </div>

                    <div
                      v-if="!freeTrial"
                      class="col-lg-12 col-md-12 col-sm-12 mb-3"
                    >
                      <label for="gst">Gst Number</label>
                      <input
                        type="text"
                        id="gst"
                        class="form-control"
                        v-model="gst"
                      />
                    </div>
                    <!-- <div v-if="!freeTrial" class="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <label for="couponCode">Apply Coupon Code</label>
                  <div class="input-group" :class="{ 'success-border': apiSuccess,'error-border': apiError }">
                      <input type="text" id="couponCode" class="form-control" :class="getClassNames2" v-model="couponCode">
                      <div class="input-group-append">
                        <button class="btn btn-primary" type="button" @click="applyCoupon">Apply</button>
                      </div>
                    </div>


              </div>
              <div v-if="!freeTrial" class="col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label for="amount">Amount</label>
                    <input  type="text" id="amount" class="form-control" v-model="PaymentDetails.total_amount" disabled>
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div
              class="text-center input-group-append"
              v-if="!freeTrial"
              style="width: 100%"
            >
              <!-- <button class="btn btn-primary" @click="detailesSaved()"   style="width: 100%; font-weight: 600;" >SUBMIT</button> -->
            </div>
            <div
              class="text-center input-group-append"
              v-else
              style="width: 100%"
            >
              <!-- <button class="btn btn-primary" id="saveAs"  @click="Save()"  style="width: 100%; font-weight: 700;" >Save</button> -->
              <button
                class="btn btn-primary"
                id="saveAs"
                @click="Save()"
                :disabled="buttonDisabled"
                style="width: 100%; font-weight: 700"
              >
                Save
              </button>
            </div>
          </div>
          <p
            style="display: flex; align-items: center; justify-content: center"
          >
            If Already Purchased ?
            <a @click="login()" style="color: #0aafe3; cursor: pointer"
              >&nbsp; Login</a
            >
          </p>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-12 rightSideBar" style="">
          <div class="successCoupon" v-if="status === 0">
            <img
              src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconS.png"
              style="height: 29px"
            />
            <span class="SCA1"
              >₹{{ this.PaymentDetails.discount }}/-&nbsp;saved!</span
            >
            <span class="SCA2">with&nbsp;the&nbsp;applied&nbsp;coupon</span>
          </div>
          <div class="plancontainer">
            <div class="header">
              <span> Select Plan</span>
            </div>
            <div class="title">
              <span
                >Explore our Free Trial and discover the <br />perfect plan for
                your needs.
              </span>
            </div>

            <div class="customplan">

              <div class="row">
                <div
                  class="col-6 tab"
                  @click="slideTo('unlimited')"
                  :class="{ active: activeTab === 'unlimited' }"
                >
                  Unlimited Plan
                </div>
                <div
                  class="col-6 tab"
                  @click="slideTo('customized')"
                  :class="{ active: activeTab === 'customized' }"
                >
                  Per User Subscription
                </div>
                <div class="col-6 slider" :style="sliderStyle"></div>
              </div>
            </div>

            <div v-if="activeTab === 'unlimited'" class="unliplan">
              <div class="planContainer1" @click="selectPlan(0)">
                <input
                  type="radio"
                  style=""
                  name="plan"
                  id="plan1"
                  value="0"
                  v-model="offerVisible"
                />
                <label for="plan1">Annual</label>
                <div class="row anualplan" style="">
                  <div class="col-12" style="margin-top: 0%">
                    <span
                      class="cross originalprice1"
                      style="object-fit: contain"
                    >
                      <!-- 49,999-->{{
                        this.formatNumber(this.firstAmount)
                      }}/- </span
                    >&nbsp;&nbsp;&nbsp;&nbsp;<span
                      style="
                        object-fit: contain;
                        float: right;
                        margin-left: 20px;
                        margin-top: -30px;
                      "
                      class="originalprice"
                      >{{ this.formatNumber(this.firstAmountEff) }}/-
                    </span>
                  </div>
                  <div class="col-12" style="margin-top: 1%">
                    <span style="float: right" class="coponSuccess"
                      >Coupon Code '{{ this.EfffecctiveCode }}'.</span
                    >
                  </div>
                </div>
              </div>
              <div class="planContainer1" @click="selectPlan(1)">
                <input
                  type="radio"
                  style=""
                  name="plan"
                  id="plan2"
                  value="1"
                  v-model="offerVisible"
                />
                <label for="plan2">3 Months</label>
                <span
                  style="margin-left: 25%; font-size: 1.7rem; font-weight: bold"
                  >{{ this.formatNumber(this.second) }}/-</span
                >
              </div>
              <div class="planContainer1" @click="selectPlan(2)">
                <input
                  type="radio"
                  style=""
                  name="plan"
                  id="plan3"
                  value="2"
                  v-model="offerVisible"
                />
                <label for="plan3">1 Months</label>
                <span
                  style="margin-left: 28%; font-size: 1.7rem; font-weight: bold"
                  >{{ this.formatNumber(this.thered) }}/-</span
                >
              </div>
            </div>

            <div v-else class="" style="position: relative;">
              <div class="row">
                <p
                  style="
                    color: #101010ce;
                    font-weight: 600;
                    margin-bottom: 7%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                  "
                >
                  Best Per User Subscription Plan in India = Rs 50 per User per
                  *month
                </p>
                <div class="col-4">
                  <lable style="color: #0aafe3">Enter User</lable>
                   <input   type="text"
               class="my-1 p-1"
               @click="selectPlan(3)"
               @input="updateResult"
               v-model="usernumber"
               placeholder="enter User above (10)"
               style="
                 width: 100%;
                 background-color: #70707012;
                 border: none; " min="1" max="100" step="1"  list="optionsList" />
              <datalist id="optionsList">

                <option v-for="option in options" :key="option" :value="option"></option>
              </datalist>
                  <!-- <input
                    type="number"
                    class="my-1 p-1"
                    @click="selectPlan(3)"
                    @input="updateResult"
                    v-model="usernumber"
                    placeholder="enter User above (10)"
                    style="
                      width: 100%;
                      background-color: #70707012;
                      border: none;
                    "
                  /> -->
                </div>
                <div class="col-4">
                  <lable style="color: #0aafe3">Enter Year</lable>
                  <input
                    type="text"

                    v-model="Years"
                    @click="selectPlan(3)"
                    @input="updateResult"
                    class="my-1 p-1"
                    style="border: none; background-color: #70707012; width: 100%"
                  />
                </div>
                <div
                  class="col-4 mb-2"
                  style="
                    display: flex;
                    align-items: center;

                    justify-content: center;
                    flex-direction: column;
                  "
                >
                  <lable style="color: #0aafe3">Plan Amount</lable>
                  <input
                    type="text"
                    class="my-1 p-1"
                    disabled
                    :value="PaymentDetails.original_amount"
                    style="border: none; width: 100%"
                  />
                </div>

                <span style="position: absolute;bottom: -30px;font-size: 0.8rem;">*All prices are excluded GST!</span>

              </div>
            </div>

            <!-- <div class="planContainer1" style="    justify-content: space-between;" @click="selectPlan(3)">

  <input type="radio" style="" name="plan" id="plan4" value="3" v-model="offerVisible">
<div class="row" style="width: 61%;"><select
      class="customplan"
      style="font: normal normal bold 1.2rem Open Sans;letter-spacing: 0.54px; color: #000000; text-transform: capitalize; opacity: 1; background-color: transparent; border: none; width: 90%;"
      v-model="customamount"
      @change="updateResult"
    >
      <option value="1" disabled selected>Select Plan</option>
      <option v-for="list of customplan" :key="list.id" :value="list.id">{{ list.name }}</option>
    </select>
  <input @click="selectPlan(3)"  @input="updateResult" v-model="usernumber" style=" border:#0AAFE3;    margin-left: 7%;  width: 80%;" placeholder="Total User Number"  type="number"/>
  </div>
  <span  style="margin-right:5%; font-size:1.7rem ;  font-weight:bold;">{{this.formatNumber(this.dyanmicPrice4)}}/-</span>
</div> -->
          </div>
          <figure
            v-if="offerVisible == 0"
            style="cursor: pointer; margin-bottom: 6.5%"
          >
            <img
              @click="offerOpen()"
              src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/offerBack.png"
              style="width: 100%"
              alt="Image description"
            />
            <figcaption>
              <img
                @click="offerOpen()"
                v-if="status === 0"
                style="margin-left: -6%; height: 8%; width: 8%"
                src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconW.png"
                alt=""
                srcset=""
              />
              <img
                @click="offerOpen()"
                v-else
                src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconW.png"
                alt=""
                srcset=""
              />

              <span>
                <span
                  @click="offerOpen()"
                  v-if="status === 0"
                  style="font-size: 20px; margin-top: 2px; margin-left: 15px"
                  >Offer applied to the bill&nbsp; &nbsp;
                </span>
                <span @click="offerOpen()" v-else
                  >&nbsp;&nbsp;Offers&nbsp;<small id="toggleText"
                    >are&nbsp;</small
                  >Available</span
                >
              </span>
              <button
                v-if="status === 0"
                class="remove-button"
                @click="removeCoupon()"
              >
                REMOVE
              </button>
            </figcaption>
          </figure>

          <div class="plancontainer">
            <h4
              style="
                font: var(--unnamed-font-style-normal) normal
                  var(--unnamed-font-weight-bold) var(--unnamed-font-size-25) /
                  34px var(--unnamed-font-family-open-sans);
                letter-spacing: var(--unnamed-character-spacing-0);
                text-align: left;
                text-decoration: underline;
                font: normal normal bold 25px/34px Open Sans;
                letter-spacing: 0px;
                color: #0aafe3;
                text-transform: uppercase;
                margin-bottom: 35px;
              "
            >
              BILLING INFORMATION
            </h4>
            <!--
  <h5 style="float:left;font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-25)/34px var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
text-align: right;
font: normal normal 600 25px/34px Open Sans;
letter-spacing: 0px;
color: #0AAFE3;">Product Name</h5>
  <h5 style="float:right;font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-25)/34px var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
text-align: right;
font: normal normal 600 25px/34px Open Sans;
letter-spacing: 0px;
color: #0AAFE3; margin-bottom:1%;">Rate(₹)</h5> <br/>
<hr style="margin-top:5% ;" />
<h5 class="pricessDescription">Price</h5>
<h5 class="pricess">Price</h5> -->

            <div class="row">
              <div class="col-12">
                <div style="float: left">
                  <h4 class="rate">Product Name</h4>
                </div>
                <div style="float: right">
                  <h4 class="rate" style="">Rate(₹)</h4>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 10px;
                "
              >
                <hr
                  style="
                    width: 100%;
                    height: 2px;
                    border: none;
                    color: #333;
                    background-color: #333;
                  "
                />
              </div>
              <div class="col-12" style="margin-top: 5%">
                <div class="disc" style="float: left">Price</div>
                <div class="disc" style="float: right">
                  {{
                    this.formatNumber(this.PaymentDetails.original_amount)
                  }}.00
                </div>
              </div>
              <div class="col-12" v-if="status === 0" style="margin-top: 4%">
                <div class="disc" style="float: left">Coupon Discount:</div>
                <div class="disc" style="float: right">
                  {{ this.formatNumber(this.PaymentDetails.discount) }}.00
                </div>
              </div>
              <div class="col-12" v-if="status === 0" style="margin-top: 4%">
                <div style="border: 1px dashed #707070; opacity: 1"></div>
              </div>

              <div v-if="status === 0" class="col-12" style="margin-top: 4%">
                <div class="disc" style="float: left; color: #101010">
                  Gross Amount:
                </div>
                <div class="disc" style="float: right; color: #101010">
                  {{ this.formatNumber(this.PaymentDetails.Grossamount) }}.00
                </div>
              </div>
              <div class="col-12" style="margin-top: 3%">
                <div class="disc" style="float: left">C-GST(9%):</div>
                <div class="disc" style="float: right">
                  {{ roundUpAmount(this.PaymentDetails.cgst) }}
                </div>
              </div>
              <div class="col-12" style="margin-top: 3%">
                <div class="disc" style="float: left">S-GST(9%):</div>
                <div class="disc" style="float: right">
                  {{ roundUpAmount(this.PaymentDetails.cgst) }}
                </div>
              </div>
              <div class="col-12" style="margin-top: 5%">
                <div style="border: 1px dashed #707070; opacity: 1"></div>
              </div>
              <div class="col-12" style="margin-top: 5%">
                <div class="disc" style="float: left">Total Payment:</div>
                <div class="disc" style="float: right">
                  {{ roundUpAmount(this.PaymentDetails.total_amount) }}
                </div>
              </div>
              <div class="col-12" style="margin-top: 8%">
                <button
                  class="PaynowButton"
                  :class="{ disabled: PaynowVisible }"
                  :disabled="PaynowVisible"
                  @click="PayNow()"
                  style="
                    background-color: #0aafe3;
                    color: #ffffff;
                    border: transparent;
                    padding-top: 10px;
                    border-radius: 8px;
                    padding-bottom: 10px;
                    width: 100%;
                    font-weight: 600;
                  "
                >
                  PAY NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showPopup1"
      ref="popupElement"
      class="popup1"
      @click.self="showPopup1 = false"
    >
      <div class="popup-content1">
        <div id="confettiContainer"></div>
        <img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrowClose.png"
          alt=""
          srcset=""
          @click="showPopup1 = false"
          style="
            cursor: pointer;
            float: right;
            margin-top: 5px;
            margin-right: 5px;
          "
        />
        <div
          style="
            display: flex;
            position: relative;
            width: 100%;
            margin-top: 10%;
            align-items: center;
            justify-content: center;
          "
        >
          <img
            src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/bigGreen.png"
            style="margin-bottom: 5%"
            alt=""
          />
        </div>
        <h1
          style="
            font: var(--unnamed-font-style-normal) normal normal 30px/41px
              var(--unnamed-font-family-open-sans);
            text-align: center;
            font: normal normal normal 30px/41px Open Sans;
            letter-spacing: 0.75px;
            color: #646464;
            margin-bottom: 10%;
          "
        >
          "{{ this.couponCode }}" applied
        </h1>
        <h1
          style="
            font: var(--unnamed-font-style-normal) normal
              var(--unnamed-font-weight-bold) 50px/45px
              var(--unnamed-font-family-open-sans);
            text-align: center;
            font: normal normal bold 50px/45px Open Sans;
            letter-spacing: 1.25px;
            color: #363636;
            text-transform: uppercase;
            margin-bottom: 5%;
          "
        >
          ₹{{ this.formatNumber(this.PaymentDetails.discount) }}/-
        </h1>

        <h1
          style="
            font: var(--unnamed-font-style-normal) normal
              var(--unnamed-font-weight-600) 38px/40px
              var(--unnamed-font-family-open-sans);
            text-align: center;
            font: normal normal 600 2.2rem Open Sans;
            letter-spacing: 0.95px;
            color: #363636;
            padding-left: 6%;
            padding-right: 6%;
            margin-bottom: 8%;
          "
        >
          savings with this coupon
        </h1>

        <h1
          @click="showPopup1 = false"
          style="
            cursor: pointer;
            font: var(--unnamed-font-style-normal) normal
              var(--unnamed-font-weight-bold) 45px/61px
              var(--unnamed-font-family-open-sans);
            text-align: center;
            font: normal normal bold 45px/61px Open Sans;
            letter-spacing: 1.13px;
            color: #fd6814;
          "
        >
          YAY!
        </h1>
      </div>
    </div>
  </div>

  <div v-if="showPopup" class="popup" @click.self="showPopup = false">
    <div class="popup-content" style="">
      <img
        src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrowClose.png"
        alt=""
        srcset=""
        @click="showPopup = false"
        style="
          cursor: pointer;
          float: right;
          margin-top: 5px;
          margin-right: 5px;
        "
      />

      <div class="" style="padding-left: 2%; padding-right: 2%">
        <!-- <span style="float:right;  width: 50px; height: 50px; font-size:1rem; background-color:gray;display:flex;align-items:center; color:white;  justify-content:center; border-radius: 50%;"></span>  -->
        <h4 class="offerd">OFFERS</h4>
      </div>
      <div
        class=""
        style="
          border: 1px;
          margin-left: 2%;
          margin-right: 2%;
          border-style: dashed;
          height: 1px;
          display: flex;
          align-items: center;
          border-color: #85a4c5;
          justify-content: center;
        "
      ></div>
      <div
        class="search"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100px;
        "
      >
        <!-- <div class="input-group" :class="{ 'success-border': apiSuccess,'error-border': apiError }"> -->
        <!-- <input type="text" id="couponCode" class="form-control" :class="getClassNames2" v-model="couponCode"> -->

        <input
          type="text"
          :class="getClassNames2"
          id="search"
          class="form-control"
          placeholder="Enter Coupon Code"
          v-model="couponCode"
          style="
            font: var(--unnamed-font-style-normal) normal normal 20px/27px
              var(--unnamed-font-family-open-sans);
            letter-spacing: var(--unnamed-character-spacing-0);
            text-align: left;
            font: normal normal normal 20px/27px Open Sans;
            letter-spacing: 0px;
            color: #5e5e5e;
            opacity: 1;
            background-color: #ffff;
            width: 320px;
          "
        />

        &nbsp;

        <button
          style="
            border: 1px solid #ffffff;
            background-color: #0aafe3;
            color: #ffff;
            padding: 7px 25px;
            font-size: 1rem;
            border-radius: 8px;
          "
          @click="applyCoupon"
        >
          APPLY
        </button>
      </div>
      <div
        class="search"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          margin-bottom: 2%;
          background-color: #ecf4fc;
          border: 1px solid #0aafe3;
          border-radius: 8px;
          margin-left: 2%;
          margin-right: 2%;
        "
      >
        <span
          style="display:flex;align-items:center; justify-content:center:font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 16px/22px var(--unnamed-font-family-open-sans);
                letter-spacing: var(--unnamed-character-spacing-0);
                text-align: left;
                font: normal normal 600 16px/22px Open Sans;
                letter-spacing: 0px;
                color: #0AAFE3;"
        >
          OR SELECTED AN OFFER (01)
        </span>
      </div>

      <div class="outerDiv" style="">
        <div
          class="ApplyCouponCodeContainer"
          v-for="item in couponlist"
          v-bind:key="item.id"
        >
          <div class="parDiv">
            <span>
              {{
                this.calculateDiscountPercentage(
                  this.PaymentDetails.original_amount,
                  item.price
                )
              }}%<br />OFF
            </span>
          </div>
          <div class="container1">
            <span class="title">IBSF Offers</span>
            <button class="applyButton" @click="appliedCouponed(item.id)">
              APPLY COUPON
            </button>
            <br />
            <span class="couponCode">Coupon Code: "{{ item.code }}"</span><br />
            <span class="couponDescription"
              >Use this coupon code and get
              {{
                this.calculateDiscountPercentage(
                  this.PaymentDetails.original_amount,
                  item.price
                )
              }}% off on the product.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <form method="POST" class="pl-5 pr-5" id="paymentForm" :action="payuUrl">
    <input type="hidden" name="key" v-model="mkey" size="64" />
    <input type="hidden" name="txnid" v-model="txnid" size="64" />
    <input
      type="hidden"
      v-if="normalAMount"
      name="amount"
      v-model="this.PaymentDetails.total_amount"
      size="64"
    />
    <input type="hidden" v-else name="amount" :value="this.amount" size="64" />

    <input type="hidden" name="productinfo" v-model="productinfo" size="64" />
    <input type="hidden" name="firstname" v-model="firstName" size="64" />
    <input type="hidden" name="service_provider" value="payu_paisa" size="64" />
    <input type="hidden" name="email" v-model="email" size="64" />
    <input type="hidden" name="phone" v-model="contactNumber" size="64" />
    <input type="hidden" name="lastname" v-model="lastName" size="64" />
    <input type="hidden" name="surl" v-model="surl" />
    <input type="hidden" name="furl" v-model="furl" />
    <input type="hidden" name="hash" id="hash" v-model="hash" size="64" />
  </form>

  <footer>
    <a
      href="https://ibtelecomprivacypolicy.infinitybrains.com/"
      style="text-decoration: none"
    >
      <h4>Terms & Condition | Privacy Policy</h4>
    </a>
    <h4>
      <img
        src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconPhone.png"
        style="height: 20px"
      />&nbsp; +91 7201933133
    </h4>
    <h4 style="display: flex; justify-content: space-between">
      <a href="https://www.facebook.com/Redjinnifood/">
        <img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/fb.png"
          style="height: 20px; margin-left: 10px"
        />
      </a>
      <a href="https://www.instagram.com/redjinniindia/">
        <img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/insta.png"
          style="height: 20px; margin-left: 10px"
      /></a>
      <a href="https://www.linkedin.com/company/redjinni/">
        <img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/linkdin.png"
          style="height: 20px; margin-left: 10px"
      /></a>
      <a href="https://twitter.com/redjinnisurat?lang=en">
        <img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/tweeter.png"
          style="height: 20px; margin-left: 10px"
      /></a>
    </h4>
  </footer>
</template>

<script>
import "typeface-open-sans";
import axios from "axios";
import JSConfetti from "js-confetti";

export default {
  data() {
    return {
      selectedNumber: 10,
      options: [],
      activeTab: "unlimited",
      customamount: 1,
      usernumber: 1,
      customplan: [],
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      companyName: "",
      couponCode: "",
      companyAddress: "",
      country: "",
      state: "",
      city: "",
      search: "",
      salesManager: "",
      firstAmount: 0,
      Years: 1,
      dyanmicPrice4: "40",
      firstAmountEff: 49999,
      second: 0,
      thered: 0,
      gst: "",
      createPassword: "",
      formError: "",
      messageClass: "",
      PaynowVisible: false,
      amountInfo: "",
      normalAMount: true,
      pincode: "",
      status: 1,
      selectedImage: "",
      EfffecctiveCode: "IBHRMS",

      fontSize: "16px",
      showPopup: false,
      showPopup1: false,
      // loading: false,
      responseStatus: null,
      responseStatus2: null,
      emailDisabled: false,
      offerVisible: 0,
      amount: "49999",
      showPassword: false,
      selectedPlan: "",
      buttonDisabled: true,
      planOne: 2,
      freeTrial: false,
      apiError: false,
      apiSuccess: false,
      loading: false,
      emailDisable: false,
      country_id: "101",

      PaymentDetails: {
        discount: "49999",
        original_amount: "49999",
        total_amount: 0,
        sgst: 0,
        cgst: 0,
        discount_amount: "49999",
        Grossamount: "",
      },
      couponlist: [],
      state_id: "",
      city_id: "",
      countries: [],
      productinfo: "Ibt",
      states: [],
      cities: [],
      virify: true,
      payuUrl: "https://secure.payu.in/_payment",
      mkey: "nxpvv9VZ",
      saltKey: "3oFxUMtWG2",
      surl: window.location.origin + "/success",
      furl: window.location.origin + "/",
      txnid: this.makeid(),
      calculatedAmount: 0,
    };
  },
  computed: {
    sliderStyle() {
      return {
        transform:
          this.activeTab === "unlimited" ? "translateX(0)" : "translateX(95%)",
      };
    },
    containerStyle() {
      return {
        backgroundColor: "#ffff",
        // color: '',
        borderRadius: "0 14px 14px 0",
        paddingRight: "50px",
        paddingLeft: "50px",
        height: this.selectedPlan ? "500px" : "auto",
        transition: "height 0.3s ease-in-out",
      };
    },
    getClassNames() {
      const classNames = [];

      if (this.responseStatus === "success") {
        classNames.push("success-border");
      } else if (this.responseStatus === "error" && this.email) {
        classNames.push("error-border", "shake");
      }

      return classNames.join(" ");
    },
    getClassNames2() {
      const classNames = [];

      if (this.responseStatus2 === "success") {
        classNames.push("success-border");
      } else if (this.responseStatus2 === "error") {
        classNames.push("error-border", "shake");
      }

      return classNames.join(" ");
    },
  },
  mounted() {
    if (window.innerWidth < 960) {
      this.$router.push("/Purchaseplan");
    } else {
      this.$router.push("/buy");
    }
    this.makeid();
    this.checkChromeMobile();
    this.scrollToZeroIndex();
    this.getData();
    this.makeid();
    this.showAmount();
    this.readallCoupon();
    this.getdynamiccustomAmount();
    this.updateOptions(this.selectedNumber);

    this.freeTrial = false;
    this.showPopup = false;
    this.plansss = "2";

    // this.showPopup1 = true;
    const planItems = document.querySelectorAll(".plan-item");
    planItems.forEach((item, index) => {
      setTimeout(() => {
        item.classList.add("show");
      }, index * 200);
    });
  },
  watch: {
    offerVisible(newVal) {
      // Perform actions based on the selected radio button
      if (newVal === "0") {
        // Code for Option 1 selected
        this.offerVisible = 0;
        this.plansss = this.planOne;
        this.showAmount();
        this.selectPlan(0);

        // Set this.offerVisible = 0 or perform any other logic
      } else if (newVal === "1") {
        // Code for Option 2 selected
        this.offerVisible = 1;
        this.showAmount1();
        this.selectPlan(1);
        this.status = 1;
        this.plansss = this.planTwo;
        // this.offerVisible = 0 ;

        // Set this.offerVisible = 1 or perform any other logic
      } else if (newVal === "2") {
        this.offerVisible = 2;
        this.selectPlan(2);
        this.showAmount2();
        this.status = 1;
        this.plansss = this.planThree;
        // Code for Option 3 selected
        // Set this.offerVisible = 2 or perform any other logic
      } else if (newVal === "3") {
        this.selectPlan(3);
        this.updateResult();

        // Code for Option 3 selected
        // Set this.offerVisible = 2 or perform any other logic
      }
    },
  },
  methods: {
    updateOptions(value) {
      this.options = [];
      // this.options.push('1 - 10');


      if (this.usernumber == '10') {
        this.usernumber = 10;
      }
        // alert(' ' , this.usernumber);
      this.options.push('1 - 10');
      // alert(this.usernumber);
      for(let i = 11 ; i<=100 ; i++){

            this.options.push(i);

          }
    },
    updateResult() {
      console.log('hello');  
     if(this.usernumber == '1 - 10'){
      this.usernumber = 10;
     }
      this.offerVisible = 3;

      this.selectPlan(3);
      if (
        this.customamount == 1 ||
        this.customamount == 2 ||
        this.customamount == 3
      ) {
        this.customamount = this.customplan[0].id;
      }
      axios
        .get(
          `https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan/${this.customamount}`
        )
        .then((res) => {
          this.selectPlan(3);

          this.plansss = res.data.data.id;
          if(this.usernumber <= 10){

                this.PaymentDetails.original_amount =
                parseInt(10) * res.data.data.amount * this.Years * 12;
                this.PaymentDetails.cgst =
                this.PaymentDetails.original_amount * (9 / 100);
                this.PaymentDetails.sgst =
                this.PaymentDetails.original_amount * (9 / 100);
                this.PaymentDetails.total_amount =
                this.PaymentDetails.sgst +
                this.PaymentDetails.cgst +
                this.PaymentDetails.original_amount;
                console.log(res.data.data.amount);
                  }
                  
                  else{
                    this.PaymentDetails.original_amount =
                parseInt(this.usernumber) * res.data.data.amount * this.Years * 12;
                this.PaymentDetails.cgst =
                this.PaymentDetails.original_amount * (9 / 100);
                this.PaymentDetails.sgst =
                this.PaymentDetails.original_amount * (9 / 100);
                this.PaymentDetails.total_amount =
                this.PaymentDetails.sgst +
                this.PaymentDetails.cgst +
                this.PaymentDetails.original_amount;
                console.log(res.data.data.amount);
                  }
        })
        .catch((error) => {
          console.log(error);
        });
      // const amount =
    },
    slideTo(tab) {
      this.activeTab = tab;
      if (tab === "unlimited") {
        this.offerVisible = 0;
        this.showAmount();
      } else {
        this.offerVisible = 1;
        this.updateResult();
      }
    },
    getdynamiccustomAmount() {
      // axios.get(`https://uatapihrms.redjinni.com/api/subscription-plans?filter={'is_user_plan': '0', 'status': '1'}`).then((res)=>{
     // axios.get(`https://uatapihrms.redjinni.com/api/subscription-plans?filter={'is_user_plan': '0', 'status': '1'}`).then((res)=>{
      axios.get(`https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan?filter={"is_user_plan": "1"}`).then((res)=>{
        this.customplan = res.data.data.data

        console.log(this.customplan);
      }).catch();
    },
   
    calculateDiscountPercentage(originalPrice, discountedPrice) {
      // console.log("thow ",originalPrice , discountedPrice);
      const percentageOff = (discountedPrice / originalPrice) * 100;

      return percentageOff.toFixed(0);
    },
    roundUpAmount(amount) {
      const roundedAmount = Number(amount).toFixed(2);
      return roundedAmount;
    },
    selectPlan(value) {
      this.offerVisible = value;
    },
    showAmount() {
      axios
        .get(
          "https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan"
        )
        .then((res) => {
          this.firstAmount = res.data.data.data[0].amount;
          this.planOne = res.data.data.data[0].id;
          this.planTwo = res.data.data.data[1].id;
          this.planThree = res.data.data.data[2].id;
          this.second = res.data.data.data[1].amount;
          this.thered = res.data.data.data[2].amount;
          // console.log("vcredoild;rejvi",this.firstAmount);
          console.log("data1", res.data.data.data[0]);
          this.amountInfo = res.data.data.data[0];
          this.amount = res.data.data.data[0].amount;
          this.PaymentDetails.original_amount = res.data.data.data[0].amount;
          // alert( this.PaymentDetails.original_amount );
          // this.PaymentDetails.total_amount = res.data.data.data[0].amount * (9 / 100) * 2 + this.PaymentDetails.original_amount;
          this.PaymentDetails.total_amount = parseFloat( this.PaymentDetails.original_amount) +   this.PaymentDetails.original_amount * (9 / 100) * 2;
          this.PaymentDetails.sgst = res.data.data.data[0].amount * (9 / 100);
          this.PaymentDetails.cgst = res.data.data.data[0].amount * (9 / 100);
          this.PaymentDetails.Grossamount = res.data.data.data[0].amount;
        });
    },
    showAmount1() {
      axios
        .get(
          "https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan"
        )
        .then((res) => {
          console.log("data2", res.data.data.data[1]);
          this.amountInfo = res.data.data.data[1];
          this.amount = res.data.data.data[1].amount;
          this.PaymentDetails.original_amount = res.data.data.data[1].amount;
          this.PaymentDetails.total_amount = parseFloat( this.PaymentDetails.original_amount) +   this.PaymentDetails.original_amount * (9 / 100) * 2;
             this.PaymentDetails.sgst = res.data.data.data[1].amount * (9 / 100);
          this.PaymentDetails.cgst = res.data.data.data[1].amount * (9 / 100);
          this.PaymentDetails.Grossamount = res.data.data.data[1].amount;
        });
    },
    showAmount2() {
      axios
        .get(
          "https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan"
        )
        .then((res) => {
          console.log("data3", res.data.data.data[2]);
          this.amountInfo = res.data.data.data[2];
          this.amount = res.data.data.data[2].amount;
          this.PaymentDetails.original_amount = res.data.data.data[2].amount;
          this.PaymentDetails.total_amount = parseFloat( this.PaymentDetails.original_amount) +   this.PaymentDetails.original_amount * (9 / 100) * 2;
            this.PaymentDetails.sgst = res.data.data.data[2].amount * (9 / 100);
          this.PaymentDetails.cgst = res.data.data.data[2].amount * (9 / 100);
          this.PaymentDetails.Grossamount = res.data.data.data[2].amount;
        });
    },

    readallCoupon() {
      axios
        .get(
          "https://salesforceapi.infinitybrains.com/api/super-admin/coupon"
        )
        .then((res) => {
          console.log("big datatatatata", res.data.data[0]);
          this.couponlist = res.data.data;
          this.EfffecctiveCode = res.data.data[0].code;

          axios
            .post(
              "https://salesforceapi.infinitybrains.com/api/applycoupon",
              {
                code: res.data.data[0].code,
                subscription_id: res.data.data[0].subscription_id,
              }
            )
            .then((res) => {
              console.log("data3", res.data.data);
              this.firstAmountEff = res.data.data.discount_price;
            });
        });
    },

    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },

    getData() {
      axios
        .get("https://api.restroworld.com/api/countries?is_light=true")
        .then((result) => {
          this.countries = result.data.data;

          if (this.country_id) {
            this.getState();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getState() {
      axios
        .get("https://api.restroworld.com/api/states_of_country?is_light=1", {
          params: {
            country_id: this.country_id,
          },
        })
        .then((result) => {
          this.states = result.data.data;
          this.state_id = ""; // Reset the selected state
          this.getCity();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCity() {
      axios
        .get("https://api.restroworld.com/api/cities_of_state?is_light=true", {
          params: {
            state_id: this.state_id,
          },
        })
        .then((result) => {
          this.cities = result.data.data;
          this.city_id = ""; // Reset the selected city
        })
        .catch((error) => {
          console.error(error);
        });
    },
    removeCoupon() {
      this.status = 1;
      this.showAmount();
    },
    handleFileUpload1() {
      const fileInput = this.$refs.fileInput;

      if (fileInput.files && fileInput.files.length > 0) {
        const images = fileInput.files;
        for (const image of images) {
          const reader = new FileReader();
          reader.readAsDataURL(image);
          reader.addEventListener("load", () => {
            const imagesArray = localStorage.getItem("images");
            let images = [];
            if (imagesArray) {
              images = JSON.parse(imagesArray);
              images.push(reader.result);
            } else {
              images.push(reader.result);
            }
            // localStorage.setItem('images', JSON.stringify(images));
          });
        }
        this.selectedImage = images[0];
        this.selectedFileName = images[0].name;
      } else {
        this.selectedImage = null;
        this.selectedFileName = "";
      }
    },
    appliedCouponed(id) {
      this.showPopup = false;
      this.showPopup1 = true;
      this.status = 0;
      axios
        .get(
          "https://salesforceapi.infinitybrains.com/api/super-admin/coupon/" +
            id
        )
        .then((res) => {
          console.log("data3", res.data);

          this.couponCode = res.data.data.code;
          this.applyCoupon();
        });
      // Wait for the Vue next tick to ensure the popup is rendered
      // this.couponCode = 'IBcoupon'
    },

    detailesSaved() {
      if (!this.cities) {
        this.formError = "Please select a city.";
      } else {
        const formData = new FormData();
        formData.append("companylogo", this.selectedImage);

        if (this.firstName) {
          formData.append("first_name", this.firstName);
        }

        if (this.lastName) {
          formData.append("last_name", this.lastName);
        }

        if (this.email) {
          formData.append("email", this.email);
        }

        if (this.contactNumber) {
          formData.append("mobile_no", this.contactNumber);
        }

        if (this.createPassword) {
          formData.append("password", this.createPassword);
        }

        if (this.companyAddress) {
          formData.append("company_address", this.companyAddress);
        }

        if (this.gst) {
          formData.append("gst_number", this.gst);
        }

        if (this.plansss) {
          formData.append("subscription_planid", this.plansss);
        }

        if (this.couponCode) {
          formData.append("code", this.couponCode);
        }

        if (this.companyName) {
          formData.append("company_name", this.companyName);
        }

        if (this.country_id) {
          formData.append("country", this.country_id);
        }

        if (this.state_id) {
          formData.append("state", this.state_id);
        }

        if (this.city_id) {
          formData.append("city", this.city_id);
        }

        if (this.Pincode) {
          formData.append("pincode", this.Pincode);
        }

        if (this.salesManager) {
          formData.append("sales_manager_name", this.salesManager);
        }

        axios
          .post(
            "https://uatapihrms.redjinni.com/api/client",
            formData
            //  ,
            //    {
            //   first_name: this.firstName,
            //       last_name: this.lastName,
            //       email: this.email,
            //       mobile_no: this.contactNumber,
            //       password: this.createPassword,
            //       company_address: this.companyAddress,
            //       gst_number : this.gst,
            //       subscription_planid: this.plansss,
            //       code: this.couponCode,
            //       company_name: this.companyName,
            //       country: this.country_id,
            //       state: this.state_id,
            //       city: this.city_id,
            //       pincode: this.Pincode,
            //       sales_manager_name: this.salesManager,

            //  }
          )
          .then((response) => {
            this.formError = response.data.message;
            this.messageClass = "success";
            let userid = response.data.data.subscription.id;
            this.PaynowVisible = false;
            localStorage.setItem("UserId", userid);
            const data1 = {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              mobile_number: this.contactNumber,
              psaaword: this.createPassword,
              address: this.companyAddress,
            };
            const serializedData = JSON.stringify(data1);
            localStorage.setItem("userDetails", serializedData);

            if (window.innerWidth <= 768) {
              this.$router.push("/purchaseplan");
              var data =
                this.mkey +
                "|" +
                this.txnid +
                "|" +
                this.PaymentDetails.total_amount +
                "|" +
                this.productinfo +
                "|" +
                this.firstName +
                "|" +
                this.email +
                "|||||||||||";
              var sha512 = require("js-sha512");
              var salt = "3oFxUMtWG2";
              var hash = sha512(data + salt);

              console.log(hash);
              console.log("Here Is yourData", data);

              document.getElementById("hash").value = hash;
              const data1 = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                productinfo: this.productinfo,
                hash: this.hash,
                contectnumber: this.contactNumber,
                password: this.createPassword,
                image: this.selectedImage,

                // ...other properties
              };

              const serializedData = JSON.stringify(data1);
              localStorage.setItem("userDetails", serializedData);

              this.$router.push("/purchaseplan");
            }

            // axios.post('https://uatapihrms.redjinni.com/api/client',{
            //   first_name: this.firstName,
            //       last_name: this.lastName,
            //       email: this.email,
            //       mobile_no: this.contactNumber,
            //       password: this.createPassword,
            //       company_address: this.companyAddress,
            //       gst_number : this.gst,
            //       subscription_planid: this.plansss,
            //       code: this.couponCode,
            //       company_name: this.companyName,
            //       country: this.country_id,
            //       state: this.state_id,
            //       city: this.city_id,
            //       pincode: this.Pincode,
            //       sales_manager_name: this.salesManager,

            //  } ).then((response) => {
            //     this.formError = response.data.message;
            //     this.messageClass = 'success';
            //     let userid = response.data.data.subscription.id;
            //     this.PaynowVisible = false;
            //     localStorage.setItem("UserId", userid);

            //     if (window.innerWidth <= 768) {
            //       var data =
            //             this.mkey +
            //             "|" +
            //             this.txnid +
            //             "|" +
            //             this.PaymentDetails.total_amount +
            //             "|" +
            //             this.productinfo +
            //             "|" +
            //             this.firstName +
            //             "|" +
            //             this.email +
            //             "|||||||||||";
            //           var sha512 = require("js-sha512");
            //           var salt = "3oFxUMtWG2";
            //           var hash = sha512(data + salt);

            //           console.log(hash);
            //           console.log("Here Is yourData",data);

            //           document.getElementById("hash").value = hash;
            //   this.$router.push('/purchaseplan');
            // }
          })
          .catch((error) => {
            this.formError = error.response.data.message;
            this.messageClass = "danger";
          });
      }
    },
    formatNumber(number) {
      number = number.toString().replace(/\D/g, "");
      let formattedNumber = "";
      let commaCount = 0;

      for (let i = number.length - 1; i >= 0; i--) {
        formattedNumber = number[i] + formattedNumber;
        commaCount++;

        if (commaCount === 3 && i > 0) {
          formattedNumber = "," + formattedNumber;
          commaCount = 0;
        }
      }

      return formattedNumber;
    },
    PayNow() {
      if (!this.city_id) {
        this.formError = "Please select a city.";
        this.messageClass = "danger";
      } else if (!this.city_id || !this.state_id) {
        this.formError = "Please select both a state and a city.";
        this.messageClass = "danger";
      } else {
        const formData = new FormData();
        formData.append("companylogo", this.selectedImage);

        if (this.firstName) {
          formData.append("first_name", this.firstName);
        }

        if (this.lastName) {
          formData.append("last_name", this.lastName);
        }

        if (this.email) {
          formData.append("email", this.email);
        }

        if (this.contactNumber) {
          formData.append("mobile_no", this.contactNumber);
        }

        if (this.createPassword) {
          formData.append("password", this.createPassword);
        }

        if (this.companyAddress) {
          formData.append("company_address", this.companyAddress);
        }

        if (this.gst) {
          formData.append("gst_number", this.gst);
        }

        if (this.plansss) {
          formData.append("subscription_planid", this.plansss);
        }
        if (this.usernumber) {
          formData.append("users", this.usernumber);
        }

        if (this.couponCode) {
          formData.append("code", this.couponCode);
        }

        if (this.companyName) {
          formData.append("company_name", this.companyName);
        }

        if (this.country_id) {
          formData.append("country", this.country_id);
        }

        if (this.state_id) {
          formData.append("state", this.state_id);
        }

        if (this.city_id) {
          formData.append("city", this.city_id);
        }
        if (this.Pincode) {
          formData.append("pincode", this.Pincode);
        }

        if (this.salesManager) {
          formData.append("sales_manager_name", this.salesManager);
        }

        axios
          .post(
            "https://salesforceapi.infinitybrains.com/api/store-vendor",
            formData
          )
          .then((res) => {
            localStorage.setItem("PD", JSON.stringify(this.PaymentDetails));
            console.warn(res.data);

            var data =
              this.mkey +
              "|" +
              this.txnid +
              "|" +
              this.PaymentDetails.total_amount +
              "|" +
              this.productinfo +
              "|" +
              this.firstName +
              "|" +
              this.email +
              "|||||||||||";
            var sha512 = require("js-sha512");
            var salt = "3oFxUMtWG2";
            var hash = sha512(data + salt);

            console.log(hash);
            console.log("Here Is yourData", data);

            document.getElementById("hash").value = hash;
            document.getElementById("paymentForm").submit();
          });
      }
    },
    resetForm() {
      this.email = ""; // Reset the email input
      if (this.responseStatus == "success") {
        this.virify = true;
      }
      this.emailDisabled = false; // Disable the input
      this.buttonDisabled = true; // Disable the save button
    },
    verifyEmail() {
      this.loading = true; // Show the spinner
      if (this.email) {
        axios
          .post("https://uatapihrms.redjinni.com/api/verifyEmail", {
            email: this.email,
          })
          .then((response) => {
            // Verification successful
            console.log(response.data); // Do something with the response data
            this.loading = false;
            if (
              response.data.message === "Record has been shown successfully."
            ) {
              // document.getElementById('');
              this.responseStatus = "error";
              this.buttonDisabled = true;
              this.virify = true;
              this.firstName = response.data.user.first_name;
              this.lastName = response.data.user.last_name;
              // this.email:,
              this.contactNumber = response.data.user.phone_no;
              this.companyName = response.data.user.companyName;
              // this.couponCode: '',
              this.companyAddress = response.data.user.address;
              // createPassword: '',
            } else {
              this.responseStatus = "success";
              this.buttonDisabled = false;
              this.emailDisabled = true;
              this.virify = false;
            }
          })
          .catch((error) => {
            // Verification failed
            console.error(error); // Handle the error

            this.responseStatus = "error";
            this.loading = false;
            setTimeout(() => {
              this.responseStatus = null;
            }, 1000);
          });
      } else {
        // Handle the error

        this.responseStatus = "error";
        this.loading = false;
        console.log("hello");
      }
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
    },
    formatAmount(value) {
      const formattedAmount = value.toLocaleString("en-US", {
        currency: "INR",
      });
      return formattedAmount;
    },
    offerOpen() {
      this.showPopup = true;
    },
    scrollToZeroIndex() {
      const targetElement = document.getElementById("left-column1");
      if (targetElement) {
        // Scroll to the top of the element
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: "smooth", // Add smooth scrolling effect if desired
        });
      }
    },
    checkChromeMobile() {
      //         var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      // if (isMobile) {
      //   document.querySelector('.rightSideBar').classList.add('hidden');
      // }else{
      //    document.querySelector('.rightSideBar').classList.remove('hidden');
      // }
    },
    applyCoupon() {
      axios
        .post(
          "https://salesforceapi.infinitybrains.com/api/applycoupon",
          {
            code: this.couponCode,
            subscription_id: this.planOne,
          }
        )
        .then((res) => {
          console.log(res.data.data);
          // this.amount = Math.ceil(res.data.data.final_amount);
          // this.normalAMount = false;
          this.responseStatus2 = "success";
          this.descountedPrice = res.data.data;
          this.PaymentDetails.original_amount = res.data.data.original_price;
          this.PaymentDetails.total_amount = res.data.data.final_amount;
          this.PaymentDetails.sgst = res.data.data.sgst;
          this.PaymentDetails.cgst = res.data.data.sgst;
          this.PaymentDetails.Grossamount = res.data.data.discount_price;
          this.PaymentDetails.discount = res.data.data.discount;
          this.apiSuccess = true;
          this.apiError = false;
          this.showPopup = false;
          this.showPopup1 = true;
          this.status = 0;
          // Wait for the Vue next tick to ensure the popup is rendered
          this.$nextTick(() => {
            const confettiContainer =
              document.getElementById("confettiContainer");
            if (confettiContainer) {
              const jsConfetti = new JSConfetti({
                target: confettiContainer,
                width: confettiContainer.offsetWidth, // Set the width to match the container's width
              });

              jsConfetti.addConfetti();
            }
          });
        })
        .catch(() => {
          this.responseStatus2 = "error";
          this.normalAMount = true;
          this.apiError = true;
          this.apiSuccess = false;
        });
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    login() {
      const data = {
        name: "John",
        age: 30,
      };
      const queryParameters = new URLSearchParams(data).toString();
      const deepLinkUrl = `ibsalesforce://open?${queryParameters}`;

      // Try to open the deep link URL
      window.location.href = deepLinkUrl;

      // Redirect to the Play Store if the deep link fails
      setTimeout(() => {
        const playStoreUrl =
          "https://play.google.com/store/apps/details?id=com.ib.ibsalesforce";
        window.location.href = playStoreUrl;
      }, 3000); // Change the timeout value as needed
      (this.firstName = ""),
        (this.lastName = ""),
        (this.email = ""),
        (this.contactNumber = ""),
        (this.companyName = ""),
        (this.couponCode = ""),
        (this.companyAddress = ""),
        (this.salesManager = ""),
        (this.gst = ""),
        (this.createPassword = ""),
        (this.pincode = "");
    },
    //   selectPlan(planId) {
    //   document.getElementById(planId).checked = true;
    //   // alert(planId);
    //     if(planId == 'plan1')
    //     {

    //         this.freeTrial = true;

    //     }else{
    //       this.freeTrial = false;
    //     }
    // }
  },
};
</script>

<style scoped>
.pricess {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    22px/30px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  float: right;
  font: normal normal 600 22px/30px Open Sans;
  letter-spacing: 0px;
  color: #3d4141;
}

@import url("https://fonts.googleapis.com/css2?family=Merienda&display=swap");
figure {
  position: relative;
  width: 100%;
}
@media (max-width: 767px) {
  .ApplyCouponCodeContainer {
    /* Adjusted styles for smaller screens */
    grid-template-columns: 1fr;
    height: auto;
    padding: 8px;
    margin: 2% 0;
    line-height: 1;
  }
}
.disc {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    22px/30px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 22px/30px Open Sans;
  letter-spacing: 0px;
  color: #3d4141;
  opacity: 1;
}
.rate {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    var(--unnamed-font-size-25) / 34px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: right;
  font: normal normal 600 25px/34px Open Sans;
  letter-spacing: 0px;
  color: #0aafe3;
}
.ApplyCouponCodeContainer {
  height: 100px;
  padding-top: 8px;
  padding-left: 8px;
  padding: 8px;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  /* justify-content: space-between; */

  /* align-items: center; */
}
.container1 {
  margin-left: 2%;
  width: 100%;
}

.title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    22px/30px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 22px/30px Open Sans;
  letter-spacing: 0px;
  color: #272727;
}
.applyButton {
  float: right;
  margin-top: 1%;
  margin-right: 1%;
  padding: 4px 10px;
  border-color: #0aafe3 !important;
  border: 1px solid;
  background-color: transparent;
  border-radius: 8px;
  color: #0aafe3;
}

.couponCode {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    18px/24px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #0aafe3;
}

.couponDescription {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    18px/24px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #5e5e5e;
}

/* Media Queries */

/* Adjust styles for screens smaller than 768px */
@media (max-width: 964px) {
  .applyButton {
    float: none;
    margin-top: 5px;
    margin-right: 0;
    text-align: center;
    /* line-height: 1; */
    margin-left: 32%;
    padding: 8px 10px;
  }
  .title {
    line-height: 1;
  }
  .couponCode,
  .couponDescription {
    text-align: center;
    line-height: 1;
  }
}
@media (max-width: 480px) {
  .applyButton {
    font-size: 10px;
  }

  .couponCode,
  .couponDescription {
    font-size: 13px;
  }
}
/* Adjust styles for screens larger than 1200px */
@media (min-width: 1200px) {
  .applyButton {
    margin-top: 2%;
    margin-right: 2%;
  }
}

.outerDiv::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}
.popup-content {
  border-radius: 15px;
  background-color: #ffffff;
  /* padding: 20px; */
  width: 46rem;
  max-width: 100%;

  height: 46rem;
  /* max-height: 60rem; */
  max-height: 80%;
}
.popup-content1 {
  border-radius: 15px;
  background-color: #ffffff;
  /* padding: 20px; */
  width: 32rem;
  max-width: 100%;

  height: 35rem;
  /* max-height: 60rem; */
  max-height: 80%;
  background-image: url("./../../ASSET/IBT_FREE_TRIAL_SCREEN/backimges.png");
}

.parDiv {
  line-height: 1;
  background-color: #0aafe3;
  height: 100%;
  border-radius: 8px;
  padding: 10px;
  width: 88px;
  font-family: "Merienda", cursive;
  text-align: left;
  font-size: 2rem;
  letter-spacing: 0px;
  color: #ffffff;
}
.custom-file-input {
  position: relative;
  overflow: hidden;
}

.custom-file-input::after {
  content: "browse";
  width: 10vh;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0aafe3;
  padding: 1px;
  color: #ffffff;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.custom-file-input:hover::after {
  background-color: #426c9e;
}

.custom-file-input:focus::after {
  outline: none;
  background-color: #b9b9b9;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  width: 0;
  height: 0;
}

.custom-file-input::before {
  content: attr(placeholder);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5em;
  background-color: transparent;
  color: #999999;
  pointer-events: none;
  transition: color 0.3s ease;
}

.custom-file-input:focus::before,
.custom-file-input:not(:placeholder-shown)::before {
  color: #ffffff;
}

.custom-file-input::file-selector-button {
  visibility: hidden;
  width: 0;
  height: 0;
}
/* .custom-file-input::before {
  content: 'Select Image';
} */
.offerd {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal bold 30px/41px Open Sans;
  letter-spacing: 0px;
  color: #0aafe3;
  text-transform: uppercase;
  opacity: 1;
  text-align: center;
  height: 101px;
  margin-left: 6%;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  /* background-image: url("../assets/bgB.png"); */
  background-color: #000000a1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../assets/bgB.png"); */
  background-color: #000000a1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.remove-button {
  border: 2px dashed #ffffffe0;
  color: white;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: "Open Sans";
  padding: 0px 23px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .remove-button {
    font-size: 16px;
    padding: 0px 15px;
  }
}

@media (max-width: 480px) {
  .remove-button {
    font-size: 14px;
    padding: 0px 10px;
  }
}

figcaption {
  position: absolute;
  top: 50%;
  left: 9.5%;
  transform: translateY(-50%);
  padding: 10px;
  font-family: "Merienda", cursive;
  font-size: 2rem;
  line-height: 42px;
  letter-spacing: 0;
  color: #ffffff;
  text-align: left;
  opacity: 1;
  text-shadow: 2px 2px 4px rgb(0, 0, 0);
}

figcaption small {
  display: inline-block;
  vertical-align: top;
  margin-top: 0px; /* Adjust the spacing value as needed */
}
figcaption span {
  display: inline-block;
  vertical-align: top;
  margin-top: 2px; /* Adjust the spacing value as needed */
}
.cross {
  position: relative;
  display: inline-block;
}
.cross::before,
.cross::after {
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
.cross::before {
  border-bottom: 1px solid #ff0000;
  -webkit-transform: skewY(-8deg);
  transform: skewY(-8deg);
}
/* .cross::after {
    border-bottom: 2px solid red;
    -webkit-transform: skewY(10deg);
    transform: skewY(10deg);
} */

.coponSuccess {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    12px/17px var(--unnamed-font-family-open-sans);
  text-align: right;
  font: normal normal bold 12px/17px Open Sans;
  letter-spacing: 0.24px;
  color: #0aafe3;
  text-transform: capitalize;
}
@media (min-width: 1568px) {
  .originalprice {
    font: normal bold 1.6rem Open Sans;
  }
  .anualplan {
    margin-left: -1px;
    margin-top: -5%;
    width: 26vh;
  }
  .outerDiv {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 50vh; /* Set the desired percentage-based height for the outerDiv */
  }
}

.originalprice1 {
  font: normal normal bold 20px/27px Open Sans;
  letter-spacing: 0.4px;
  color: #535353;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 20px;
  margin-left: 30px;
}
@media (max-width: 1568px) {
  .anualplan {
    margin-left: 40px;
    margin-top: -5%;
    width: 50%;
  }
  .originalprice1 {
    font-size: 1rem;
    line-height: 20px;
    margin-top: 3vh;
    margin-left: 10%;
  }
  .originalprice {
    font: normal bold 1.3rem Open Sans;
    margin-top: 20px;
    margin-left: 20px;
  }
  .outerDiv {
    overflow: auto;
    height: 38vh; /* Set the desired percentage-based height for the outerDiv */
  }
}

@media (max-width: 768px) {
  .originalprice1 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
    margin-left: 15px;
  }
}

@media (max-width: 480px) {
  .originalprice1 {
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    margin-left: 5px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal bold 35px/47px Open Sans;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0.7px;
  color: #0aafe3;
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 5px;
}
.left-column {
  background-color: #ffff;
  max-height: 780px;
  max-width: 910px;
  border-radius: 14px;
  padding-right: 50px;
  padding-left: 50px;
}

@media (max-width: 768px) {
  .left-column {
    max-height: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
.rightSideBar.hidden {
  display: none;
}

.rightSideBar {
  max-width: 520px;
  width: 100%;
  /* height:100vh; */
  /* max-height: 800px; */
  overflow: auto;
}
.rightSideBar::-webkit-scrollbar {
  width: 0; /* Hide the scrollbar */
}

.successCoupon {
  background: #ecfff3 0% 0% no-repeat padding-box;
  border: 1px solid #009436;
  border-radius: 15px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  margin-bottom: 30px;
  width: 100%;
}
.successCoupon span {
  margin-top: 0.5%;
}
.SCA1 {
  font: normal bold 17px/20px Arial;
  letter-spacing: 0px;
  color: #009436;
  opacity: 1;
  margin-right: 10px;
  margin-left: 8px;
}

.SCA2 {
  font: normal normal 17px/20px Arial;
  letter-spacing: 0px;
  color: #009436;
  opacity: 1;
}

.plancontainer {
  /* height: 485px; */
  max-height: fit-content;
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
  border-radius: 20px;
  padding-top: 35px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 35px;
}
.title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    16px/20px var(--unnamed-font-family-open-sans);
  text-align: center;
  font: normal normal 600 16px/20px Open Sans;
  letter-spacing: 0.32px;
  color: #555555;
  opacity: 1;
  margin-bottom: 30px;
}
.planContainer1 {
  display: flex;
  align-items: center;
  padding-left: 25px;
  /* padding-right:25px; */
  width: 100%;
  height: 87px;
  background: #e9f4ff;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 15px;
}
.planContainer1:last-child {
  margin-bottom: 0px;
}

.planContainer1 label {
  /* font-size: 1.5rem; */
  margin-left: 23px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    27px/37px var(--unnamed-font-family-open-sans);
  color: var(--unnamed-color-000000);
  text-align: left;
  font: normal normal bold 27px/37px Open Sans;
  letter-spacing: 0.54px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 30px;
  height: 30px;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #0aafe3;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.main-container {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
  color: #2c2c2c;
  /* max-height: 100%; */
  /* Other styles for the main container */
}
.selected {
  transform: scale(1.1); /* Increase the size by 10% */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}

.input-group-append .btn,
.input-group-append .btn::after,
.input-group-append .btn::before,
.input-group-append .btn:hover,
.input-group-append .btn:active {
  background-color: #0aafe3;
  border: #0aafe3;
}
.inner-container {
  text-align: center;
  margin-top: 6%;
  /* Styles for the inner container */
}

.inner-container h2 {
  font-weight: 700;
  font-size: 2.5rem;
}
.plan-item {
  border-radius: 5px;
  border: transparent;
  height: 70px;
  background-color: #ffff;
  color: black;
}

.plan-item p {
  font-size: 1.2rem;
  float: left;
  margin-top: 4.5%;
  padding-left: 10px;
}
.inner-container p {
  font-weight: 700;
  font-size: 1.5rem;
}
.form {
  margin-top: 2%;
  margin-bottom: 2%;
}

select {
  background-color: #f5f5f5;
  /* Add any other custom styles for input[type="text"] */
}

input[type="text"] {
  background-color: #f5f5f5;
  /* Add any other custom styles for input[type="text"] */
}
input[type="email"] {
  background-color: #f5f5f5;
  /* Add any other custom styles for input[type="text"] */
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
textarea:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select:focus,
.uneditable-input:focus {
  border-color: #f5f5f5;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075) inset, 0 0 8px #b9b9b9;
  margin-top: 0.5%;
  outline: 0 none;
  background-color: #f5f5f5;
}
.arrow-image {
  float: right;
  margin-top: -14%;
  margin-right: 10px;
}
/* .form-check-input ,.form-check-input:checked::after,.form-check-input::before, .form-check-input.active .form-check-input:focus  {
  margin-top: 6%;
  border: solid black;
  font-size: 1.2rem;
  outline: none;

} */
.form-check-input {
  /* Add your custom styles here */
  /* Example styles */
  font-size: 1.2rem;
  margin-top: 6%;
  border: 2px solid #000;
  border-radius: 50%;
  background-color: #fff;
}

.form-check-input:checked {
  /* Add custom styles for the checked state */
  /* Example styles */
  background-color: #0aafe3;
}

.form-check-input:focus {
  /* Add custom styles for the focused state */
  /* Example styles */
  outline: none;
  box-shadow: 0 0 0 4px #f5f5f5;
}
ul li {
  text-decoration: none;
}
.plan-item {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s, transform 0.5s;
}

.plan-item.show {
  opacity: 1;
  transform: translateY(0);
}
footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* justify-content: center; */
  background-color: #0a112b;
}
footer h4 {
  height: 5vh;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.success-border {
  border-color: rgb(68, 216, 68);
}
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

/* .success-border {
  border: 2px solid green;
} */

.error-shake {
  border-color: red;
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.hidden {
  display: none;
}
.error-border {
  border-color: red;
}
.success {
  color: rgb(59, 201, 59);
}

.danger {
  color: rgb(236, 120, 120);
}
.price {
  padding-right: 10px;
}

.innercontainer {
  border: solid;

  width: 40vh;
}
@media screen and (min-width: 948px) {
  .arrow-image2 {
    float: right;
    margin-top: -6%;
    margin-right: 10px;
  }
  .arrow-image1 {
    float: right;
    margin-top: -12%;
    margin-right: 10px;
  }
  footer {
    display: flex;
    justify-content: space-around;
    align-items: bottom;
    background-color: #0a112b;
    bottom: 0;
    margin-top: 0%;
  }
  footer h4 {
    height: 5vh;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
  .containerl {
    float: left;
    width: 50%;
  }
  .containerr {
    float: right;
    width: 25%;
  }
}
@media screen and (max-width: 948px) {
  .rightSideBar {
    display: none;
  }
  footer {
    display: grid;
    justify-content: space-around;
    align-items: bottom;
    background-color: #0a112b;
    bottom: 0;
    margin-top: 0%;
  }
  footer h4 {
    height: 5vh;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }

  .arrow-image2 {
    float: right;
    margin-top: -7%;
    margin-right: 10px;
  }
  .containerl {
    float: none;
    width: 100%;
  }
  .containerr {
    float: none;
    width: 100%;
  }
  .arrow-image1 {
    float: right;
    margin-top: -7%;
    margin-right: 10px;
  }
}

.tab {
  padding: 2.3%;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: 900;
  color: #ffffff;
  z-index: 1;
}

.slider {
  padding: 4.8%;
  border-radius: 24px;
  height: max-content;
  position: absolute;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  z-index: 0;
  font-weight: 900;
  background-color: #00abf5;
  transition: transform 0.3s ease-in-out;
}

.customplan .row {
  margin-bottom: 5%;
  border-radius: 26px;
  padding: 1%;
  position: relative;
  background: #bcbcbcc0;
}

.PaynowButton {
  /* Your custom styles for the button */
}

.PaynowButton.disabled {
  /* Styles for the button when disabled */
  opacity: 0.6;
  cursor: not-allowed;
  /* Add any additional styling you want for the disabled state */
}
</style>