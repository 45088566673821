<template>
  <header><title>IBSF</title></header>
  <router-view/>
</template>
<!-- <link src="https://www.googletagmanager.com/gtag/js?id=G-FJ2LP6N0N1" /> -->
<script>

  // window.dataLayer = window.dataLayer || [];
  // function gtag(){dataLayer.push(arguments);}
  // gtag('js', new Date());

  // gtag('config', 'UA-261662850-1');




export default {
  name: 'App',

    
  components: {
   
  }
}
</script>

<style>
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */

}
</style>
