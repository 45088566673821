<template>
  <div class="bg2 main-cntainer">
    <div class="" style="margin-left: 7%; margin-right: 0%; max-width: 100%">
      <!-- <router-link to="/">
        <img
          src="../assets/Ibicon.svg"
          class=""
          style="width: 14vh; margin-top: 3rem; z-index: 0; float: left"
      /></router-link> -->
      <div class="d-flex gap-5 align-items-center w-100">
        <router-link to="/">
          <img
            src="../assets/Ibicon.svg"
            class=""
            style="width: 14vh; margin-top: 3rem; z-index: 0; float: left"
        /></router-link>

        <div
          class="d-flex gap-5 align-items-center justify-content-end w-100"
          style="margin-right: 3rem"
        >
          <!-- ibtals and blog page -->
          <router-link to="/ib-talks" class="text-decoration-none text-black">
            <h3 class="fw-semibold fs-3 lh-lg m-0 p-0">IBTalks</h3>
          </router-link>

          <router-link to="/blogs" class="text-decoration-none text-black">
            <h3 class="fw-semibold fs-3 lh-lg m-0 p-0">Blogs</h3>
          </router-link>

          <router-link to="/buy">
            <img src="../../ASSET/skip.svg" />
            <!-- style="
              height: 2%;
              width: 14vh;
              float: right;
              margin-top: 10vh;
              margin-right: 5%;
            " -->
          </router-link>
        </div>
      </div>

      <br />

      <div style="margin-top: 30vh">
        <img class="WelcomeImage" src="../assets/rgf.svg" style="" />
        <h1 style="font-size: 2.8rem">
          WELCOME TO THE <br />
          IB SALESFORCE
        </h1>
        <p
          style="
            width: 65%;
            font-size: 1.2rem;
            text-align: left;
            margin-top: 1%;
          "
        >
          Take Your Business To The Next Level With Ibsalesforce, The Ultimate
          CRM Solution. With Centralized Data Management, Improved Customer
          Insights, Increased Sales, Enhanced Collaboration, And Customization
          Options, Ibsalesforce Empowers Businesses Of All Sizes To Manage Their
          Clients And Sales More Effectively. By Providing Real-Time Updates On
          Deals And A Complete View Of Your Customers, Ibsalesforce Allows You
          To Make Informed Decisions And Provide Better Customer Service.
          Whether You're A Small Business Or A Large Enterprise, Ibsalesforce
          Can Help You Streamline Your Sales Process And Drive Growth. Try
          Ibsalesforce Today And Take Your Business To New Heights.
        </p>
      </div>
      <router-link to="/WatchVideo">
        <label
          class="btnb"
          style="
            padding-left: 3%;
            padding-right: 3%;
            padding-top: 0.5%;
            padding-bottom: 0.5%;
            color: white;
            background-color: blue;
            border-radius: 7px;
          "
          >WATCH VIDEO</label
        >
      </router-link>

      <router-link to="/freeTrial">
        <label
          class="btnb"
          style="
            padding-left: 2%;
            padding-right: 2%;
            padding-top: 0.5%;
            margin-top: 3%;
            margin-left: 2%;
            padding-bottom: 0.5%;
            color: white;
            /* background-color: blue; */
            border-radius: 7px;
          "
          >Get 15 Days Free Trial</label
        >
      </router-link>
    </div>
    <center>
      <div
        class="Feature"
        style="
          text-align: center;
          margin-top: 17vh;
          max-width: 90%;
          /* margin-left: 7%; */
        "
      >
        <div class="row" style="text-align: center">
          <h1 style="font-size: 7vh; max-width: 100%; font-weight: 600">
            Features <br />
            <center>
              <hr
                style="
                  height: 4px;
                  background-color: rgb(130, 221, 250);
                  color: rgb(130, 221, 250);
                  width: 15rem;
                  margin-top: 0;
                  margin-bottom: 4%;
                "
              />
            </center>
          </h1>
        </div>
      </div>

      <div class="bg3 main-conatiner" style="height: 80vh; z-index: 0"></div>
      <div
        class="cards"
        style="max-width: 80%; margin-left: 0%; font-weight: 1200"
      >
        <div class="row row-cols-1 row-cols-md-3 g-4" style="">
          <div class="col">
            <div class="card" style="width: 100%; height: 99%">
              <h1
                style="
                  margin-left: 1.1rem;
                  float: left;
                  position: absolute;
                  z-index: 1;
                "
              >
                1.
              </h1>
              <img
                src="../assets/01.svg"
                class="card-img-top"
                style="margin-top: 0%; height: 40vh; z-index: 0"
                alt="..."
              /><br />
              <div class="card-body" style="text-align: center">
                <h3 style="">CENTRALIZED DATA MANAGEMENT</h3>
                <p class="card-text" style="font-size: 1rem">
                  Ibsalesforce Provides A Centralized Platform To Store And
                  Manage All Your Customer Data, Making It Easier To Access And
                  Analyze.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card" style="width: 100%; height: 99%">
              <h1
                style="
                  margin-left: 1.1rem;
                  float: left;
                  position: absolute;
                  z-index: 1;
                "
              >
                2.
              </h1>
              <img
                src="../assets/02.svg"
                class="card-img-top"
                style="margin-top: 0%; height: 40vh; z-index: 0"
                alt="..."
              /><br />
              <div class="card-body" style="text-align: center">
                <h3 style="">INCREASED SALES</h3>
                <p class="card-text" style="font-size: 1rem">
                  Ibsalesforce Helps You Stay On Top Of Your Sales Pipeline,
                  Providing You With Real-Time Updates On Deals, Enabling You To
                  Close More Sales And Increase Your Revenue.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="card"
              style="width: 100%; height: 99%; max-height: fit-content"
            >
              <h1
                style="
                  margin-left: 1.1rem;
                  float: left;
                  position: absolute;
                  z-index: 1;
                "
              >
                3.
              </h1>
              <img
                src="../assets/03.svg"
                class="card-img-top"
                style="margin-top: 0%; height: 40vh; z-index: 0"
                alt="..."
              /><br />
              <div class="card-body" style="text-align: center">
                <h3 style="">
                  ENHANCED <br />
                  COLLABORATION
                </h3>
                <p class="card-text" style="font-size: 1rem">
                  Ibsalesforce Provides A Centralized Platform For Your Sales
                  Team, Enabling Them To Work Together And Share Information,
                  Leading To Improved Communication And Collaboration.
                </p>
              </div>
            </div>
          </div>
        </div>
        <router-link to="/TemporaryPlans">
          <button class="pur" style="">PURCHASE NOW &nbsp; >></button>
        </router-link>
      </div>
    </center>
    <!-- <img src="../assets/bg2.svg" style=" width: 100%; margin-top: -33%; z-index: 0;" />  -->
  </div>
  <footer>
    <a
      href="https://www.infinitybrains.com/IbsfPrivacy"
      style="text-decoration: none"
    >
      <!-- <h4>Terms & Condition | Privacy Policy</h4> -->
      <h4>Terms & Condition | Deleations Policy</h4>
    </a>
    <!-- href="http://localhost:8081/privacyPolicy" -->
    <a
      href="https://www.ibsalesforce.com/privacypolicy"
      style="text-decoration: none"
    >
      <h4>Privacy Policy</h4>
    </a>
    <h4>
      <img
        src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconPhone.png"
        style="height: 20px"
      />&nbsp; +91 7201933133
    </h4>
    <h4 style="display: flex; justify-content: space-between">
      <a href="https://www.facebook.com/Redjinnifood/">
        <img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/fb.png"
          style="height: 20px; margin-left: 10px"
        />
      </a>
      <a href="https://www.instagram.com/redjinniindia/">
        <img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/insta.png"
          style="height: 20px; margin-left: 10px"
      /></a>
      <a href="https://www.linkedin.com/company/redjinni/">
        <img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/linkdin.png"
          style="height: 20px; margin-left: 10px"
      /></a>
      <a href="https://twitter.com/redjinnisurat?lang=en">
        <img
          src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/tweeter.png"
          style="height: 20px; margin-left: 10px"
      /></a>
    </h4>
  </footer>
  <!-- <img src="../assets/bg2.svg" style="bottom: 0; margin-left: -3%; margin-right: -3%; width: 100%; left: 0; right: 0; " /> -->
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
// import 'bootstrap/dist/js/bootstrap.js'

export default {
  name: "HomePage",
  components: {},

  // // remove has key for payment
  // mounted() {
  //   if (localStorage.getItem("paymentAddRemoverPlan")) {
  //     // localStorage.removeItem("paymentAddRemoverPlan");
  //     localStorage.removeItem("paymentAddRemoverPlan");
  //   }

  //   if (localStorage.getItem("paymentLogoPlan")) {
  //     // localStorage.removeItem("paymentAddRemoverPlan");
  //     localStorage.removeItem("paymentLogoPlan");
  //   }
  // },
  methods: {
    reb() {
      // alert('https://play.google.com/store/apps/details?id=com.ib.ibsalesforce');
      // Change this to your custom URL scheme
      const appUrl =
        "https://play.google.com/store/apps/details?id=com.ib.ibsalesforce"; // Replace with the deep link for your app
      window.location.href = appUrl;
    },
  },
  mounted() {
    // Only one mounted is here
    if (
      localStorage.getItem("UserEmail") ||
      localStorage.getItem("UserFirstName") ||
      localStorage.getItem("UserLastName") ||
      localStorage.getItem("UserMobileNo") ||
      localStorage.getItem("SelectedPlanId")
    ) {
      localStorage.removeItem("UserEmail");
      localStorage.removeItem("UserFirstName");
      localStorage.removeItem("UserLastName");
      localStorage.removeItem("UserMobileNo");
      localStorage.removeItem("SelectedPlanId");
    }
  },
};
</script>

<style scoped>
/* *{
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/bg2.svg'), url('../assets/bg3.svg');
    background-size: cover;
    background-position: center center;
    height: 100vh;
} */

.BgImg2 {
  z-index: 0;
  margin-bottom: 0;
  bottom: 0;
}
.bg2 {
  background-image: url("../assets/bg3.svg");
  /* margin-left: -20%; */
  background-size: cover;
  /* height: 0%; */
}

.bg3 {
  background-image: url("../assets/bg2.svg");
  margin-top: 10%;
  background-size: cover;
}

.btnb {
  background: linear-gradient(180deg, #06bcf5 0%, #035e7b 100%);
}
label:hover {
  cursor: pointer;
}
/* Small devices (portrait phones, 576px and below) */
@media screen and (max-width: 576px) {
  .cards {
    margin-left: -10%;
  }

  .bg3 {
    width: 0%;
  }
  /* Your CSS styles for small devices go here */
}
.pur {
  border: 1px solid;
}

/* Medium devices (landscape phones, 576px to 768px) */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .cards {
    margin-left: -10%;
  }
  .bg3 {
    width: 0%;
  }
  .pur {
    border: 1px solid;
  }
  /* Your CSS styles for medium devices go here */
}

@media screen and (min-width: 1824px) and (max-width: 2000px) {
  footer {
    display: flex;
    justify-content: space-around;
    align-items: bottom;
    background-color: #0a112b;
    bottom: 0;
    margin-top: 4%;
    font-size: 1.1rem;
  }
  footer h4 {
    height: 5vh;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pur {
    padding-left: 3%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 7px;
    padding-right: 3%;
    padding-top: 0.5%;
    z-index: 1;
    padding-bottom: 0.5%;
    color: black;
    background-color: white;
    border: transparent;
  }
  .cards {
    margin-top: -45%;
    width: 100%;
  }
  .WelcomeImage {
    width: 30%;
    z-index: 1;
    height: 400px;
    float: right;
    margin-top: 0vh;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1740px) {
  footer {
    display: flex;
    justify-content: space-around;
    align-items: bottom;
    background-color: #0a112b;
    bottom: 0;
    margin-top: 0%;
  }
  footer h4 {
    height: 5vh;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
  }
  .pur {
    padding-left: 3%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 7px;
    padding-right: 3%;
    padding-top: 0.5%;
    z-index: 1;
    padding-bottom: 0.5%;
    color: black;
    background-color: white;
    border: transparent;
  }
  .cards {
    margin-top: -45%;
    width: 100%;
  }

  .WelcomeImage {
    width: 30%;
    z-index: 1;
    height: 400px;
    float: right;
    margin-top: 0vh;
  }
}
@media screen and (max-width: 948px) {
  footer {
    display: grid;
    justify-content: space-around;
    align-items: bottom;
    background-color: #0a112b;
    bottom: 0;
    margin-top: 0%;
  }
  footer h4 {
    height: 5vh;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pur {
    padding-left: 3%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 7px;
    padding-right: 3%;
    padding-top: 0.5%;
    width: 80%;
    height: 8vh;
    font-size: 1.2rem;
    font-weight: bold;
    z-index: 1;
    padding-bottom: 0.5%;
    color: white;
    border-color: transparent;
    background-color: #0aafe3;
  }
  .cards {
    margin-top: -160%;
    width: 100%;
  }

  .WelcomeImage {
    width: 100%;
    margin-bottom: 10%;
    animation: fadeIn 1s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
:root {
  scroll-behavior: smooth;
  display: none;
}
/* Large devices (tablets, 768px to 992px) */
</style>
