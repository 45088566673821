<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container h-100 w-100">
    <div class="row">
      <div class="col">
        <div>
          <div v-if="showPopup" class="popup">
            <div class="popup-content text-center">
              <div class="row w-100">
                <div class="col">
                  <img
                    src="../assets/Scroll--4.svg"
                    style="width: 23rem; margin-top: 6%"
                  />
                </div>
                <div class="col-12 w-100">
                  <h3 style="margin-top: 2%; color: #00a00b !important">
                    Your Payment Was Successful!
                  </h3>
                </div>
                <div class="col-12">
                  <h5 style="margin-top: 1%; color: rgb(65, 65, 65)">
                    Payment reciept sent to your email.
                  </h5>
                </div>
                <div class="col-12 text-center">
                  <button @click="reb()" key="" class="bb" style="">
                    <img src="../assets/arrow.png" /> GET STARTED
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
// import router from '@/router'3impor
import axios from "axios";
// import instagram from 'vue-instagram'
export default {
  name: "SuccessPage",
  data() {
    return {
      showPopup: false,
      formData: {
        name: "",
        email: "",
      },
      uIdUpdPayAddRemove: "",

      // apiUrl: "https://uatbackendsales.infinitybrains.com/public/api/",
      apiUrl: "https://ibsalesbackend.redjinni.com/api/",
    };
  },
  methods: {
    reb() {
      const data = {
        name: "John",
        age: 30,
      };
      const queryParameters = new URLSearchParams(data).toString();
      const deepLinkUrl = `ibsalesforce://open?${queryParameters}`;

      // Try to open the deep link URL
      window.location.href = deepLinkUrl;

      // Redirect to the Play Store if the deep link fails
      setTimeout(() => {
        const playStoreUrl =
          "https://play.google.com/store/apps/details?id=com.ib.ibsalesforce";
        window.location.href = playStoreUrl;
      }, 3000); // Change the timeout value as needed
    },

    async updateAddremoverPaymentStatus() {
      // console.log(
      //   "Payment status update function called and Payment id is: ",
      //   this.uIdUpdPayAddRemove
      // );
      try {
        const response = await axios.post(
          `${this.apiUrl}update-payment-advertisment/${this.uIdUpdPayAddRemove}`,
          {
            payment_status: "1",
          }
        );

        if (response.data.status === true) {
          // Payment status successfully updated
          console.log("Payment status updated successfully.");
        } else {
          // Payment status update failed, show an error message
          console.error(
            "Error updating payment status:",
            response.data.message
          );
        }
      } catch (error) {
        // Handle API request error
        console.error("API request error:", error);
      }
    },
  },
  mounted() {
    this.showPopup = true;

    if (localStorage.getItem("paymentAddRemoverPlan")) {
      const data = JSON.parse(localStorage.getItem("paymentAddRemoverPlan"));

      if (data.hashKey && data.UserId) {
        this.uIdUpdPayAddRemove = data.UserId;
        console.log(this.uIdUpdPayAddRemove);
        this.updateAddremoverPaymentStatus();
      }
    } else {
      let useridss = localStorage.getItem("UserId");
      axios
        .post(
          "https://salesforceapi.infinitybrains.com/api/update-paymentstatus/" +
            useridss,
          {
            payment_status: "1",
          }
        )
        .then((res) => {
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style scoped>
/* .container {
} */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/bgB.png");
  display: flex;
  justify-content: center;
  align-items: center;
}
.bb {
  margin-top: 6%;
  font-size: 0.8rem;
  height: 2.5rem;
  width: 11rem;
  background: linear-gradient(180deg, #06bcf5 0%, #035e7b 100%);
  color: white;
}
.popup-content {
  border-radius: 7px;
  background-color: #fff;
  /* padding: 20px; */
  width: 30rem;
  height: 30rem;
  /* max-height: 60rem; */
  max-height: 80%;
  overflow-y: auto;
  background-image: url("../assets/Group2262.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
