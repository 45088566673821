<template>
    <div class="maincontainer" id="maincontainer">
        <div class="container" style="">

             <div class="successCoupon" v-if="status === 0">
      <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconS.png" style="height: 25px;" />
      <span class="SCA1">₹{{this.PaymentDetails.discount}}/-&nbsp;saved!</span>
      <span class="SCA2">with&nbsp;the&nbsp;applied&nbsp;coupon</span>
    </div>
    <div class="plancontainer">
      <div class="header">
          <span> Select Plan</span>
      </div>
      <div class="title">
        Explore our Free Trial and discover the perfect plan for your needs. All prices are excluding GST.
      </div>

      <div class="planContainer1">
  <input type="radio"  style="" name="plan" id="plan1" value="0" v-model="offerVisible">
  <label for="plan1">Annual</label>

  <div class="row" style=" justify-content: center;    justify-content: center;
    margin-top: -2%;
    width: 63%;
    margin-left: 29%;">
    <div class="col-6  textamount" style="" >
      <span class="amount cross">1,99,999/-</span>
    </div>
    <div class="col-6  textamount1" style="" >
      <span class="amount1">49,999/-</span>
    </div>
    <div class="col-12 textamount1">
      <span class="decript">Coupon Code 'IBcoupon'.</span>
    </div>
  </div>


</div>
<div class="planContainer1">

  <input type="radio" style="" name="plan" id="plan2" value="1" v-model="offerVisible">
  <label for="plan2" style="">6 Months</label>
  <span  style="margin-left:62%;font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 20px/27px var(--unnamed-font-family-open-sans);
color: var(--unnamed-color-000000);
text-align: right;
font: normal normal bold 20px/27px Open Sans;
letter-spacing: 0.4px;
color: #000000;
text-transform: capitalize;">29,999/-</span>
</div>
<div class="planContainer1">
  <input type="radio" style="" name="plan" id="plan3" value="2" v-model="offerVisible">
  <label for="plan3">1 Months</label>
  <span  style="margin-left:65%;font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 20px/27px var(--unnamed-font-family-open-sans);
color: var(--unnamed-color-000000);
text-align: right;
font: normal normal bold 20px/27px Open Sans;
letter-spacing: 0.4px;
color: #000000;
text-transform: capitalize;  font-weight: 700 bolder;">5,999/-</span>
</div>


      </div>
      <figure v-if="offerVisible == 0"   class="figure-container" style="cursor: pointer; margin-bottom:6.5%;">
  <img  @click="offerOpen()" src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/offerBack.png" class="offer-image" alt="Image description">
  <figcaption  class="offer-caption">
    <img @click="offerOpen()" v-if="status === 0" class="offer-icon" src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconW.png" alt="">
    <img v-else @click="offerOpen()"  class="offer-icon" style="    margin-left: 5%;
    height: 9%;
    margin-top: -2%;
    width: 9%;"   src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/iconW.png" alt="">

    <span>

      <span @click="offerOpen()" v-if="status === 0" class="offer-text">Offer applied to the bill&nbsp; &nbsp;</span>
      <span v-else @click="offerOpen()" class="offer-text1" style="" >&nbsp;&nbsp;Offers&nbsp;<small id="toggleText">are&nbsp;</small>Available</span>
    </span>
    <span  v-if="status === 0" class="remove-button" @click.self="removeCoupon()">REMOVE</span>
  </figcaption>
</figure>

<div class="plancontainer2">
       
       <h4 style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-25)/34px var(--unnamed-font-family-open-sans);
 letter-spacing: var(--unnamed-character-spacing-0);
 text-align: left;
 text-decoration: underline;
 font: normal normal bold 25px/34px Open Sans;
 letter-spacing: 0px;
 color: #2F518A;
 text-transform: uppercase;margin-bottom:35px">BILLING INFORMATION</h4>
  <div class="row">
      <div class="col-12">
        <div style="    float: left;"><h4  class="rate"  >Product Name</h4></div>
        <div  style="float: right;"><h4 class="rate" style="">Rate(₹)</h4></div>
      </div>
      <div style="display: flex;align-items: center;justify-content: center;height: 10px; "><hr style="width: 100%;height:2px;border:none;color:#333;background-color:#333;"/></div>
      <div class="col-12" style="margin-top: 5%;">

          <div class="disc" style="float: left;">Price</div>
          <div class="disc" style="float: right;">{{ this.PaymentDetails.original_amount }}.00</div>

      </div>
      <div class="col-12" v-if="status === 0" style="margin-top: 4%;">

        <div class="disc" style="float: left;">Coupon Discount:</div>
        <div class="disc" style="float: right;">{{this.PaymentDetails.original_amount }}</div>



    </div>
    <div class="col-12" v-if="status === 0" style="margin-top: 4%;">
      <div  style="border: 1px dashed #707070;opacity: 1;"></div>
    </div> <div v-if="status === 0"  class="col-12" style="margin-top: 4%;">

        <div class="disc" style="float: left; color: #101010;">Gross Amount:</div>
        <div class="disc" style="float: right; color: #101010;">{{ this.PaymentDetails.Grossamount }}</div>

    </div>
      <div class="col-12" style="margin-top: 3%;">

        <div class="disc" style="float: left;">C-GST(9%):</div>
        <div class="disc" style="float: right;">{{this.PaymentDetails.cgst}}{{ roundUpAmount(this.PaymentDetails.cgst) }}</div>

    </div>
    <div class="col-12" style="margin-top: 3%;">

        <div class="disc" style="float: left;">S-GST(9%):</div>
        <div class="disc" style="float: right;">{{ roundUpAmount(this.PaymentDetails.cgst) }}</div>

    </div>
    <div class="col-12" style="margin-top: 5%;">
      <div style="border: 1px dashed #707070;opacity: 1;"></div>
    </div>
    <div class="col-12" style="margin-top: 5%;">

        <div class="disc" style="float: left;">Total Payment:</div>
        <div class="disc" style="float: right;">{{ roundUpAmount(this.PaymentDetails.total_amount) }}</div>

    </div>
    <div class="col-12" style="margin-top: 8%;">
      <button
    class="PaynowButton"
    :class="{ disabled: PaynowVisible }"
    :disabled="PaynowVisible"
    @click="PayNow()"
    style="background-color: #0AAFE3; color: #ffffff; border: transparent; padding-top: 10px; border-radius: 8px; padding-bottom: 10px; width: 100%; font-weight: 600;"
  >
    PAY NOW
  </button>
    </div>
    </div>
        <!-- <div class="row">
      <div class="col-12">
        <div style="    float: left;"><h4  class="rate"  >Product Name</h4></div>
        <div  style="float: right;"><h4 class="rate" style="">Rate(₹)</h4></div>
      </div>
      <div style="display: flex;align-items: center;justify-content: center;height: 10px; "><hr style="width: 100%;height:2px;border:none;color:#333;background-color:#333;"/></div>
      <div class="col-12" style="margin-top: 4%;">
        
          <div class="disc" style="float: left;">Price</div>
          <div class="disc" style="float: right;">49,999.00</div>

          
          
      </div>
      <div class="col-12" v-if="status === 0" style="margin-top: 4%;">
        
        <div class="disc" style="float: left;">Coupon Discount:</div>
        <div class="disc" style="float: right;">20,000.00</div>

        
        
    </div>
    <div class="col-12" v-if="status === 0" style="margin-top: 4%;">
      <div  style="border: 1px dashed #707070;opacity: 1;"></div>
    </div> <div v-if="status === 0"  class="col-12" style="margin-top: 4%;">
        
        <div class="disc" style="float: left; color: #101010;">Gross Amount:</div>
        <div class="disc" style="float: right; color: #101010;">29,999.00</div>
        
    </div>
      <div class="col-12" style="margin-top: 3%;">
        
        <div class="disc" style="float: left;">C-GST(9%):</div>
        <div class="disc" style="float: right;">4,499.91</div>
        
    </div>
    <div class="col-12" style="margin-top: 3%;">
        
        <div class="disc" style="float: left;">S-GST(9%):</div>
        <div class="disc" style="float: right;">4,499.91</div>
        
    </div>
    <div class="col-12" style="margin-top: 4%;">
      <div style="border: 1px dashed #707070;opacity: 1;"></div>
    </div>
    <div class="col-12" style="margin-top: 4%;">
        
        <div class="disc" style="float: left; color: #101010;">Total Payment:</div>
        <div class="disc" style="float: right; color: #101010;">58,998.00</div>
        
    </div>
    <div class="col-12" style="margin-top: 8%;">
      <button class="" @click="PayNow()"   style="
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 16px/22px var(--unnamed-font-family-open-sans);
color: var(--unnamed-color-ffffff);
text-align: center;
font: normal normal bold 16px/22px Open Sans;
letter-spacing: 0.32px;
color: #FFFFFF;
text-transform: uppercase;background: #0AAFE3 0% 0% no-repeat padding-box;
border-radius: 8px;
width: 100%;
height: 41px;
border: transparent;
" >PAY NOW</button>
      
    </div>
    </div> -->
    </div>

        </div>
        
        <div v-if="showPopup1" ref="popupElement" class="popup1" @click.self="showPopup1 = false">
            
            <div class=" popup-content1" >
              <div id="confettiContainer1"></div>
              <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrowClose.png" alt="" srcset=""  @click="showPopup1 = false" style=" cursor: pointer;float:right; margin-top:5px; margin-right:5px; "/>
              <div style="display:flex; position: relative; width:100%; margin-top:10%;  align-items:center;justify-content:center;">
              <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/bigGreen.png" style="margin-bottom:5%" alt="">
                  
              </div>
              <h1 style="font: var(--unnamed-font-style-normal) normal normal 30px/41px var(--unnamed-font-family-open-sans);
      text-align: center;
      font: normal normal normal 30px/41px Open Sans;
      letter-spacing: 0.75px;
      color: #646464; margin-bottom:10%;">"IBcoupon" applied</h1>
          <h1 style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 50px/45px var(--unnamed-font-family-open-sans);
      text-align: center;
      font: normal normal bold 50px/45px Open Sans;
      letter-spacing: 1.25px;
      color: #363636;
      text-transform: uppercase;margin-bottom:5%;">₹{{this.PaymentDetails.discount}}/-</h1>
      
      <h1 style="font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 38px/40px var(--unnamed-font-family-open-sans);
      text-align: center;
      font: normal normal 600 2.2rem Open Sans;
      letter-spacing: 0.95px;
      color: #363636;
      padding-left:6%;
      padding-right:6%;
              margin-bottom:8%;
      ">savings with this coupon</h1>
      
      
              
      <h1 @click="showPopup1 = false"  style=" cursor: pointer; font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 45px/61px var(--unnamed-font-family-open-sans);
      text-align: center;
      font: normal normal bold 45px/61px Open Sans;
      letter-spacing: 1.13px;
      color: #FD6814;
      ">YAY!</h1>
      
      </div>
      
            
      </div>
        <div v-if="showPopup" class="popup" @click.self="showPopup = false">

<div class=" popup-content" style="">
  <img src="./../../ASSET/IBT_FREE_TRIAL_SCREEN/arrowClose.png" alt="" srcset=""  @click="showPopup = false" style=" cursor: pointer;float:right; margin-top:5px; margin-right:5px; "/>

        <div class="" style="  padding-left:2%;  padding-right:2% ;">
          <!-- <span style="float:right;  width: 50px; height: 50px; font-size:1rem; background-color:gray;display:flex;align-items:center; color:white;  justify-content:center; border-radius: 50%;"></span>  -->
          <h4 class="offerd">   OFFERS </h4>
        </div>
        <div class="" style=" border:1PX;margin-left:2%;  margin-right:2% ;   border-style: dashed;height:1px; display:flex; align-items:center; border-color:#85a4c5 ; justify-content:center;  " ></div>
      <div class="search" style="display:flex;align-items:center;justify-content:center; height:100px;">

        <input type="text"  :class="getClassNames2" id="search" class="form-control" placeholder="Enter Coupon Code"   v-model="couponCode" style=" font: var(--unnamed-font-style-normal) normal normal 20px/27px var(--unnamed-font-family-open-sans);
                      letter-spacing: var(--unnamed-character-spacing-0);
                      text-align: left;
                      font: normal normal normal 20px/27px Open Sans;
                      letter-spacing: 0px;
                      color: #5E5E5E;
                      opacity: 1;   background-color:#ffff;  width:320px;">

                      &nbsp;

                      <button style="    border: 1px solid #FFFFFF; background-color:#0AAFE3; color:#ffff;padding:7px 25px ; font-size:1rem;
                      border-radius: 8px;"  @click="applyCoupon()">APPLY</button>


      </div>
      <div class="search" style="display:flex;align-items:center;justify-content:center; height:40px;margin-bottom: 2%; background-color:#ECF4FC;border:1px solid #0AAFE3;border-radius: 8px; margin-left:2%;margin-right:2%;">

                        <span  style="display:flex;align-items:center; justify-content:center:font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 16px/22px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #0AAFE3;" >  OR SELECTED AN OFFER (01) </span>




                    </div>

      <div class="outerDiv" style="">
        <div class="ApplyCouponCodeContainer" v-for="(item) in couponlist " v-bind:key="item.id" >

<div class="parDiv">
  <span>
  {{item.percentage}}%<br>OFF
</span>

</div>
<div class="container1">
<span class="title">IBT Offers</span>
<button class="applyButton" @click="appliedCouponed(item.id)">APPLY COUPON</button>
<br>
<span class="couponCode">Coupon Code: "{{item.code}}"</span><br/>
<span class="couponDescription">Use this coupon code and get {{item.percentage}}% off on the product.</span>
</div>

  </div>




      
        </div>

</div>
</div>
</div>
<form method="POST" class="pl-5 pr-5" id="paymentForm" :action="payuUrl">
  <input type="hidden" name="key" v-model="mkey" size="64" />
  <input type="hidden" name="txnid" v-model="txnid" size="64" />
  <input type="hidden"  name="amount" v-model="this.PaymentDetails.total_amount" size="64" />
  <!-- <input type="hidden" v-else name="amount" :value="this.amount" size="64" /> -->

   <input type="hidden" name="productinfo" v-model="this.PaymentDetails.productinfo" size="64" />
   <input type="hidden" name="firstname" v-model="this.PaymentDetails.firstName" size="64" />
  <input type="hidden" name="service_provider" value="payu_paisa" size="64" />
  <input type="hidden" name="email" v-model="this.PaymentDetails.email" size="64" />
  <input type="hidden" name="phone" v-model="this.PaymentDetails.contactNumber" size="64" />
  <input type="hidden" name="lastname" v-model="this.PaymentDetails.lastName" size="64" />
  <input type="hidden" name="surl" v-model="surl" />
  <input type="hidden" name="furl" v-model="furl" />
  <input type="hidden" name="hash" id="hash" v-model="hash" size="64" />
</form>
</template>
<script>
  import JSConfetti from 'js-confetti'
  import axios from 'axios';
export default {
    data(){
        return{

            status:1,
            showPopup:false,
            showPopup1:false,
            offerVisible:0,
            PaynowVisible:false,
            couponCode:'',
            couponlist:[],
            PaymentDetails:{
        discount:'',
          original_amount:'49999',
          total_amount:'52',
          sgst:'',
          cgst:'',
          discount_amount:'',
          Grossamount:'',
          lastName:'',
          email:'',
          firstName:'',
          productinfo:'',


      },
      userDetails:[],
      payuUrl: "https://secure.payu.in/_payment",
      mkey: "nxpvv9VZ",
      saltKey: "3oFxUMtWG2",
      salt:"3oFxUMtWG2",
      surl: window.location.origin + "/success",
      furl: window.location.origin + "/",
      txnid: this.makeid(),
        }
    
    },computed: {
    containerStyle() {
      return {
        backgroundColor: '#ffff',
        // color: '',
        borderRadius: '0 14px 14px 0',
        paddingRight: '50px',
        paddingLeft: '50px',
        height: this.selectedPlan ? '500px' : 'auto',
        transition: 'height 0.3s ease-in-out',

      };
    },
    getClassNames() {
    const classNames = [];

    if (this.responseStatus === 'success') {
      classNames.push('success-border');
    } else if (this.responseStatus === 'error' && this.email) {
      classNames.push('error-border', 'shake');
    }

    return classNames.join(' ');
  },
  getClassNames2() {
    const classNames = [];

    if (this.responseStatus2 === 'success') {
      classNames.push('success-border');
    } else if (this.responseStatus2 === 'error') {
      classNames.push('error-border', 'shake');
    }

    return classNames.join(' ');
  },

  },watch: {
    offerVisible(newVal) {
      // Perform actions based on the selected radio button
      if (newVal === '0') {
        // Code for Option 1 selected
        this.offerVisible = 0 ;
        this.showAmount();
        // Set this.offerVisible = 0 or perform any other logic
      } else if (newVal === '1') {
        // Code for Option 2 selected
        alert('name');
        this.offerVisible = 1 ;
        this.showAmount1();
        this.status = 1;
        // Set this.offerVisible = 1 or perform any other logic
      } else if (newVal === '2') {
        this.offerVisible = 2 ;
        this.showAmount2();
                this.status = 1;
        // Code for Option 3 selected
        // Set this.offerVisible = 2 or perform any other logic
      }
    }
  },
    methods:{
      PayNow(){
       
   
        var data =
                  this.mkey +
                  "|" +
                  this.txnid +
                  "|" +
                  this.PaymentDetails.total_amount +
                  "|" +
                  this.PaymentDetails.productinfo +
                  "|" +
                  this.PaymentDetails.firstName +
                  "|" +
                  this.PaymentDetails.email +
                  "|||||||||||";
                var sha512 = require("js-sha512");
                var salt = "3oFxUMtWG2";
                console.log(data + salt);
                var hash = sha512(data + salt);

                console.log("Here Is hash",hash);
                console.log("Here Is yourData",data);

                document.getElementById("hash").value = hash;
                document.getElementById("paymentForm").submit();
                


      },
    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
      readallCoupon(){

        axios.get('https://salesforceapi.infinitybrains.com/api/Coupon').then((res)=>{
                        console.log("data3",res.data);
                        
                  this.couponlist =  res.data.data;
              })



        },
        offerOpen(){
            this.showPopup = true;


        },
        roundUpAmount(amount) {
      const roundedAmount = Number(amount).toFixed(2);
      return roundedAmount;
    },
    appliedCouponed(id){
      this.showPopup = false;
      this.showPopup1 = true;
      this.status = 0;
      axios.get('https://salesforceapi.infinitybrains.com/api/Coupon/'+id).then((res)=>{
                        console.log("data3",res.data);
                        
                  this.couponCode =  res.data.data.code;
                  this.applyCoupon();
              })
  // Wait for the Vue next tick to ensure the popup is rendered
  // this.couponCode = 'IBcoupon'
      
    },
    applyCoupon() {
  axios.post('https://salesforceapi.infinitybrains.com/api/applycoupon', {
    code: this.couponCode,
    subscription_id: '1'
  })
    .then((res) => {
      console.log(res.data.data);
      this.responseStatus2 = 'success';
      this.status = 0;
      const jsConfetti = new JSConfetti()

      jsConfetti.addConfetti();
      this.descountedPrice = res.data.data;
      this.PaymentDetails.original_amount =  res.data.data.original_price;
            this.PaymentDetails.total_amount = res.data.data.final_amount;
            this.PaymentDetails.sgst = res.data.data.sgst;
            this.PaymentDetails.cgst = res.data.data.sgst;
            this.PaymentDetails.Grossamount = res.data.data.discount_price;
            this.PaymentDetails.discount = res.data.data.discount;
      this.apiSuccess = true;
      this.apiError = false;
      this.showPopup = false;
      this.showPopup1 = true;
      
    })
    .catch(() => {
      this.responseStatus2 = 'error';
      this.normalAMount = true;
      this.apiError = true;
      this.apiSuccess = false;
    });
},
    scrollToZeroIndex() {
      const targetElement = document.getElementById('maincontainer');
      if (targetElement) {
        // Scroll to the top of the element
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: 'smooth' // Add smooth scrolling effect if desired
        });
      }
    },
    removeCoupon(){
        this.showPopup = false;
        this.showPopup1 = false;
      this.status = 1;
    
    },
    showAmount(){

axios.get('https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan/3').then((res)=>{


    console.log("data1",res.data.data);
    this.amountInfo = res.data.data;
    this.amount = res.data.data.amount;
    this.PaymentDetails.original_amount =  res.data.data.amount;
    this.PaymentDetails.total_amount = res.data.data.totalamount;
    this.PaymentDetails.sgst = res.data.data.sgst;
    this.PaymentDetails.cgst = res.data.data.cgst;
    this.PaymentDetails.Grossamount = res.data.data.amount;
    alert(this.PaymentDetails.sgst)
})
},
showAmount1(){

axios.get('https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan/4').then((res)=>{


console.log("data2",res.data.data);
this.amountInfo = res.data.data;
this.amount = res.data.data.amount;
this.PaymentDetails.original_amount =  res.data.data.amount;
this.PaymentDetails.total_amount = res.data.data.totalamount;
this.PaymentDetails.sgst = res.data.data.sgst;
this.PaymentDetails.cgst = res.data.data.sgst;
this.PaymentDetails.Grossamount = res.data.data.amount;
})
},
showAmount2(){

axios.get('https://salesforceapi.infinitybrains.com/api/super-admin/subscription-plan/5').then((res)=>{


console.log("data3",res.data.data);
this.amountInfo = res.data.data;
this.amount = res.data.data.amount;
this.PaymentDetails.original_amount =  res.data.data.amount;
this.PaymentDetails.total_amount = res.data.data.totalamount;
this.PaymentDetails.sgst = res.data.data.sgst;
this.PaymentDetails.cgst = res.data.data.sgst;
this.PaymentDetails.Grossamount = res.data.data.amount;
})
}
},
 
    mounted(){
        alert('helo');
        this.showPopup1 = false;
        this.scrollToZeroIndex();
        this.showAmount();
        this.readallCoupon();
        const serializedData = localStorage.getItem('userDetails');
  const userDetails = JSON.parse(serializedData);
        this.PaymentDetails.firstName = userDetails.firstName;
        this.PaymentDetails.lastName = userDetails.lastName;
        this.PaymentDetails.email = userDetails.email;
        this.PaymentDetails.productinfo = userDetails.productinfo;
        console.log(this.PaymentDetails);



              //   contectnumber
              // : 
              // "5669856985"
              // email
              // : 
              // "cdewegav@buid.dshj"
              // first_name
              // : 
              // "cdy"
              // last_name
              // : 
              // "gcdyg"
              // productInformation
              // : 
              // "Ibt"

      }
}
</script>
<style scoped>
.container{
    background-color: #F6F6F6;
    padding-top:10px;
  padding-left:10px;
  padding-right:10px;
  padding-bottom:10px;
}
.maincontainer{
    background-color: #F6F6F6;
}
.successCoupon {
  background: #ECFFF3 0% 0% no-repeat padding-box;
  border: 2px solid #009436;
  border-radius: 15px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
  padding: 12px;
}
.successCoupon span{
  margin-top: 0.5%;
}
.SCA1 {
  font: normal bold 0.9rem Arial;
  letter-spacing: 0px;
  color: #009436;
  opacity: 1;
  margin-right: 10px;
  margin-left: 8px;
}

.SCA2 {
  font: normal normal 0.9rem Arial;
  letter-spacing: 0px;
  color: #009436;
  opacity: 1;

}
.plancontainer{
  height: 354px;
  width: 100%;
  background-color: white;
  margin-bottom: 15px;
  border-radius: 20px;
  padding: 4px;

}
.plancontainer2{
  height: fit-content;
  width: 100%;
  background-color: white;
  margin-bottom: 15px;
  border-radius: 20px;
  padding: 12px 15px;

}
.title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)  0.72rem   var(--unnamed-font-family-open-sans);
text-align: center;
font: normal normal 600  0.72rem   Open Sans;
letter-spacing: 0.28px;
color: #555555;
margin-bottom: 20px;
}
.header{
  display: flex;
  align-items: center;
  justify-content: center;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-25)/34px var(--unnamed-font-family-open-sans);
text-align: center;
text-decoration: underline;
font: normal normal bold 25px/34px Open Sans;
letter-spacing: 0.5px;
color: #0AAFE3;
text-transform: capitalize;
  margin-bottom: 10px;
}



.textamount {
  display: flex;
  justify-content: flex-start;
}

.textamount1 {
  display: flex;
  justify-content: center;
}
.decript{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 12px/17px var(--unnamed-font-family-open-sans);
text-align: right;
font: normal normal bold 12px/17px Open Sans;
letter-spacing: 0.24px;
color: #0AAFE3;
text-transform: capitalize;
margin-top: 2.5%;
}
.amount {
  font: normal normal bold 1.2rem Open Sans;
  letter-spacing: 0.4px;
  color: #000000b9;
  text-transform: capitalize;
  margin-left: 12.5%;
}
.amount1 {
  font: normal normal bold 20px/27px Open Sans;
  letter-spacing: 0.4px;
  color: #000000;
  text-transform: capitalize;
  margin-right: 12.5%;
}
.cross {
    position: relative;
    display: inline-block;
}
.cross::before, .cross::after {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
}
.cross::before {
    border-bottom: 1px solid #FF0000;
    -webkit-transform: skewY(-8deg);
    transform: skewY(-8deg);

}


.planContainer1{
  display: flex;
  align-items: center;
  padding-left: 25px;
  /* padding-right:25px; */
  width: 98%;
height: 67px;
background: #E9F4FF ;
border-radius: 10px;
opacity: 1;
margin-bottom: 15px;margin-left: 2px;margin-right: 0px;
}
.planContainer1:last-child{
    margin-bottom: 0px;
}

.planContainer1 label {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 20px/27px var(--unnamed-font-family-open-sans);
color: var(--unnamed-color-000000);
text-align: right;
font: normal normal bold 20px/27px Open Sans;
letter-spacing: 0.4px;
color: #000000;
text-transform: capitalize;
        color: #000000;
        /* font-size: 1.5rem; */
        width: fit-content;
        position: absolute;
        margin-left: 40px;
    }





input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 25px;
  height: 25px;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}
input[type="radio"]::before {
  content: "";
  width: 17px;
  height: 17px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #0AAFE3;
}


input[type="radio"]:checked::before {
  transform: scale(1);
}.figure-container {
  position: relative;
  width: 100%;
}

.offer-image {
  width: 100%;
}

.offer-caption {
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  padding: 10px;
  font-family: 'Merienda', cursive;
  font-size: 1rem;
  line-height: 42px;
  letter-spacing: 0;
  color: #FFFFFF;
  text-align: left;
  opacity: 1;
  text-shadow: 0px 1px 3px #001B48;
}

.offer-icon {
  margin-left: 3%;
  height: 7%;
  width: 7%;
}

.offer-text {
  font-size: 15px;
  margin-top: 2px;
  margin-left: 5px;

}
.offer-text1 {
    font-size: 24px;
    margin-top: 2px;
    margin-left: 11px;

}
.remove-button {
    border: 2px dashed #ffffffc5;
    color: white;
    background-color: transparent;
    cursor: pointer;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 16px/22px var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
text-align: center;
font: normal normal 600 16px/22px Open Sans;
letter-spacing: 0px;
color: #fcfcfcdc;
text-transform: uppercase;
    padding: 4px 17px;
    margin-left: -2%;
    border-radius: 8px; 
    text-shadow:none;

}
.disc{

    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 17px/23px var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
text-align: left;
font: normal normal 600 17px/23px Open Sans;
letter-spacing: 0px;
color: #3d4141ea;
opacity: 1;
}
.rate{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 21px/28px var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
text-align: left;
font: normal normal 600 21px/28px Open Sans;
letter-spacing: 0px;
color: #2A4C86;
}
.offerd{
  display:flex;align-items:center;justify-content:center;  font: normal normal bold 30px/41px Open Sans;
  letter-spacing: 0px;
  color: #0AAFE3;
  text-transform: uppercase;
  opacity: 1;
  text-align: center; height: 101px;
  margin-left:6%;
}
.popup-content {
  border-radius: 15px;
  background-color: #FFFFFF;
  /* padding: 20px; */
  width: 46rem;
  max-width: 100%;
  
  height: 46rem;
  /* max-height: 60rem; */
  max-height: 80%;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../assets/bgB.png"); */
  background-color:#000000a1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.outerDiv {
  overflow: auto;
  height: 38vh; 
  max-height: fit-content;  
  /* Set the desired percentage-based height for the outerDiv */
}

.outerDiv {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 40vh; /* Set the desired percentage-based height for the outerDiv */
}

@media (max-width: 767px) {
  .ApplyCouponCodeContainer {
    /* Adjusted styles for smaller screens */
    grid-template-columns: 1fr;
    height: fit-content;
    padding: 8px;
    margin: 2% 0;
    line-height: 1;
  }
}

.ApplyCouponCodeContainer {
  height: 91px;
  padding-top: 8px ;
  padding-left:8px;
  padding: 8px;
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #C5C5C5;
border-radius: 10px;
margin-left: 2%;
margin-right:2%;
margin-top: 2%;
margin-bottom:2%;
display: flex;
  /* justify-content: space-between; */
  
  /* align-items: center; */
}
.parDiv{
  line-height: 1;
background-color:#0AAFE3 ;
height:71px;
border-radius:8px;
padding: 10px;
width: 71px;
font-family: 'Merienda', cursive;;
text-align: left;
font-size: 1.6rem;
letter-spacing: 0px;
color: #ffffff;
}

.title1 {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 17px/23px var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal 600 17px/23px Open Sans;
    letter-spacing: 0px;
    color: #272727;
    
  }.couponCode {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 12px/17px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #0AAFE3;
}

.couponDescription {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 12px/17px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #5E5E5E;
}
.container1 {
    margin-left: 2%;
    width: 100%;
  }
  .applyButton {
  float: right;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 4px #00000029;
border: 1px solid #0AAFE3;
border-radius: 5px;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 11px/23px var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
text-align: center;
font: normal normal 600 11px/23px Open Sans;
letter-spacing: 0px;
color: #0AAFE3;
text-transform: uppercase;
}
.popup-content1 {
  border-radius: 15px;
  background-color: #FFFFFF;
  /* padding: 20px; */
  width: 32rem;
  max-width: 100%;
  
  height: 35rem;
  /* max-height: 60rem; */
  max-height: 80%;
  background-image:url('./../../ASSET/IBT_FREE_TRIAL_SCREEN/backimges.png');
}
.popup1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../assets/bgB.png"); */
  background-color:#000000a1;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>